import React from "react";
import "./splash.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const Splash = ({ setShowLaunchPage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isDissappear, setIsDissappear] = useState(false);
  const navigate = useNavigate();

  const launchSite = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsDissappear(true);
      setTimeout(() => {
        setShowLaunchPage(false);
        navigate("/");
      }, 1000);
    }, 3000);
  };
  return (
    <div
      className={`h-screen flex items-center justify-center launch-bg transition-all duration-1000 ease-in-out ${
        isDissappear && "opacity-0"
      }`}
      //   style={{ backgroundImage: "url(/public/assets/img/launch.jpg)" }}
    >
      <div className="launch-page">
        <div
          className="text-5xl md:text-7xl leading-tight text-gray-300 text-block drop-shadow-lg flex flex-row items-center justify-center"
          style={{ fontFamily: "Bebas Neue" }}
        >
          <h1
            className={` ${
              isDissappear && "opacity-0"
            } transition-all duration-500 ease-in-out`}
          >
            National
            <br /> Educator
            <br /> Awards
            <br /> 2024
          </h1>
          <img
            src="/assets/img/award.webp"
            className={`w-32 md:w-40 2xl:w-64  ${
              isDissappear && "opacity-0"
            } transition-all duration-500 ease-in-out`}
          />
        </div>
        <button
          className={`bg-orange-500 min-w-[200px] hover:bg-orange-700 text-white font-bold py-4 px-8 rounded text-center mx-auto mt-20 text-xl transition-all duration-500 ease-in-out ${
            isLoading && "min-w-[50px]"
          } ${isDissappear && "opacity-0"}`}
          onClick={() => launchSite()}
        >
          {isLoading ? (
            <div className="flex flex-row items-center">
              <img
                width={40}
                height={40}
                priority
                src="/assets/img/loading.svg"
                alt="Follow us on Twitter"
              />
            </div>
          ) : (
            "LAUNCH NOW"
          )}
        </button>
      </div>
    </div>
  );
};
