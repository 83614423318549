import React from "react";

export const Team = () => {
  const originalMembers = [
    {
      name: "Professor Rahula Attalage",
      image: "/assets/team/rahula.webp",
    },
    {
      name: "Dr. Malitha Wijesundara",
      image: "/assets/team/malitha.webp",
    },
  ];

  const additionalMembers = [
    {
      name: "Prof. Charitha Jayaruk",
      image: "/assets/team/Prof.-Jayaruk-Thambiliyagodage.webp",
    },
    {
      name: "Dr. Virajith Gamage",
      image: "/assets/team/Dr.-Virajith-Gamage.webp",
    },
    {
      name: "Dr. Samurdhi Jayamaha",
      image: "/assets/team/Dr.-Samurdhi-Jayamaha.webp",
    },
    { name: "Mr. Lakmal Eranda", image: "/assets/team/Mr.-Lakmal-Eranda.webp" },
    {
      name: "Ms. Uthpala Ranasingnhe",
      image: "/assets/team/Ms.-Uthpala-Ranasinghe.webp",
    },
    {
      name: "Mr. Pramadhi Atapattu",
      image: "/assets/team/Mr.-Pramadhi-Atapattu.webp",
    },
    {
      name: "Ms. Muditha Muthucumarana",
      image: "/assets/team/Ms.-Muditha-Muthucumarana.webp",
    },
    {
      name: "Ms. Ashani Pieiris",
      image: "/assets/team/Ms.-Ashani-Peiris.webp",
    },
    {
      name: "Ms. Helmi Gammanpila",
      image: "/assets/team/Ms.-Helmi-Gammanpila.webp",
    },
    {
      name: "Mr. Shanitha Mirihana",
      image: "/assets/team/Mr.-Shanitha-Mirihana.webp",
    },
    {
      name: "Mr. Thimira Abeywardhana",
      image: "/assets/team/Mr.-Thimira-Abeywardhana.webp",
    },
    {
      name: "Ms. Uthmani Thathsarani",
      image: "/assets/team/Ms.-Uthmani-Thathsarani.webp",
    },
  ];

  return (
    <div
      className="flex flex-col h-full min-h-screen bg-slate-900 category-bg-color"
      id="team"
    >
      <div className="max-w-[1280px] mx-auto py-6 lg:py-16">
        <div className="text-center lg:mx-8 flex justify-center flex-col my-6 items-center">
          <h1
            className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-2 lg:my-0 px-8 lg:px-0 section-title relative w-fit text-center"
            style={{ fontFamily: "Bebas Neue" }}
            data-aos="fade-left"
            data-aos-delay="100"
          >
            Our Team
          </h1>
          <p
            className="mt-4 lg:mt-8 text-white text-md lg:text-lg w-full lg:w-3/4 px-8"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            NEA is driven by a dynamic team led by Professor Rahula Attalage and
            Dr. Malitha Wijesundara. Their leadership, academic expertise, and
            unwavering commitment to the project ensure its success and
            establish it as the benchmark for recognizing and honoring
            outstanding educators in Sri Lanka.
          </p>
        </div>

        {/* Original Team Members - Centered */}
        <div className="py-3 sm:py-8 flex flex-col sm:flex-row justify-center items-center gap-10">
          {originalMembers.map((member, index) => (
            <div
              className="flex flex-col justify-center items-center"
              key={index}
            >
              <img
                src={member.image}
                alt={member.name}
                className="w-[200px] h-[250px] sm:w-[300px] sm:h-[400px] object-cover object-top rounded-lg my-4"
                data-aos="fade-up"
                data-aos-delay="100"
                loading="lazy"
              />
              <h1 className="text-lg font-bold sm:text-xl capitalize tracking-wider text-[#dd7538] lg:my-0 px-8 lg:px-0 relative w-fit text-center">
                {member.name}
              </h1>
            </div>
          ))}
        </div>

        <div className="py-3 sm:py-8">
          <div>
            <div className="max-w-6xl mx-auto grid gap-8 grid-cols-1 md:grid-cols-3 lg:grid-cols-4 justify-items-center">
              {additionalMembers.map((member, index) => (
                <div
                  className="flex flex-col justify-center items-center"
                  key={index}
                >
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-[150px] h-[200px] sm:w-[200px] sm:h-[300px] object-cover object-top rounded-lg my-4"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  />
                  <h1 className="text-lg font-bold sm:text-xl capitalize tracking-wider text-[#dd7538] lg:my-0 px-8 lg:px-0 relative w-fit text-center">
                    {member.name}
                  </h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
