import { useRef, useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { Home } from "./pages/home";
import Header from "./components/header";
import { Apply } from "./pages/apply";
import AOS from "aos";
import "aos/dist/aos.css";
import { ContactUs } from "./components/contactus";
import SocialMediaIcons from "./components/floatingIcon";
import { Splash } from "./components/splash/splash";
import axios from "axios";
import FullScreenLoader from "./components/loader";
import { Winners } from "./pages/winners";
import { JudgesTable } from "./pages/judgestable";
import { GeneralEducation } from "./pages/generalEducation";
import { HigherEducation } from "./pages/higherEducation";
import Navigation from "./components/navigation";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  const [showLaunchPage, setShowLaunchPage] = useState(true);
  const [isLaunchChecked, setIsLaunchChecked] = useState(false);

  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://qf858c2ct0.execute-api.ap-south-1.amazonaws.com/is-launched"
  //     )
  //     .then((res) => {
  //       setIsLaunchChecked(true);
  //       setShowLaunchPage(!res.data.isLaunched);
  //     })
  //     .catch((err) => {
  //       setIsLaunchChecked(true);
  //       console.log(err);
  //     });
  // }, []);
  AOS.init();
  return (
    <div id="container-flex" className="bg-slate-900 overflow-x-hidden">
      <FloatingWhatsApp
        accountName="National Educator Awards"
        phoneNumber="94740740711"
        avatar="/assets/img/dp.webp"
        buttonClassName="floating-whatsapp"
      />
      <BrowserRouter>
        {/* {!isLaunchChecked ? (
            <div className="h-screen w-full">
              <FullScreenLoader />
            </div>
          ) : 
          showLaunchPage ? (
            <Splash setShowLaunchPage={setShowLaunchPage} />
          ) : 
          ( */}
        <>
          <Routes>
            <Route
              path="/launch"
              element={<Splash setShowLaunchPage={setShowLaunchPage} />}
            />
          </Routes>
          {/* <Header /> */}
          <Navigation />
          <SocialMediaIcons />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/winners" element={<Winners />} />
            {/* <Route path="/judgestable" element={<JudgesTable />} /> */}
            <Route path="/apply">
              <Route index element={<Apply />} />
              <Route path=":state/:orderidparam" element={<Apply />} />
            </Route>
            {/* <Route path="/*" element={<Home />} /> */}
            <Route path="/general-edu" element={<GeneralEducation />} />
            <Route path="/higher-edu" element={<HigherEducation />} />
          </Routes>
          <ContactUs />
        </>
        {/* )} */}
      </BrowserRouter>
    </div>
  );
}

export default App;
