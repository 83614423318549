import { motion, useScroll, useTransform } from "framer-motion";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const ShinyText = ({ text, isHidden }) => {
  return (
    <div
      className={`relative italic capitalize text-xl md:text-2xl font-bold text-white overflow-hidden max-w-full  ${
        isHidden ? "hidden md:inline-block" : "md:hidden inline-block"
      }`}
    >
      <motion.span
        className="relative z-10 block pr-2"
        initial={{ backgroundPosition: "200%" }}
        animate={{ backgroundPosition: "-200%" }}
        transition={{ repeat: Infinity, duration: 4, ease: "linear" }}
        style={{
          backgroundImage: "linear-gradient(90deg, #4b2c06, #FF8C00, #4b2c06)",
          backgroundSize: "200% 50%",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          display: "inline-block",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </motion.span>
    </div>
  );
};

export default function ParallaxNew() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  const backgroundY = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);
  const titleY = useTransform(scrollYProgress, [0, 0.85], ["0%", "-40%"]);
  const descriptionY = useTransform(scrollYProgress, [0, 0.9], ["0%", "-40%"]);
  const awardY = useTransform(scrollYProgress, [0, 0.8], ["0%", "-30%"]);

  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const navigate = useNavigate();

  const handleApplyClick = () => {
    setOverlayOpen(true);
  };

  const handleSelection = (path) => {
    setOverlayOpen(false);
    navigate(path);
  };

  return (
    <div
      ref={ref}
      className="w-full h-screen overflow-hidden relative grid place-items-center"
    >
      <motion.div
        className="text-4xl md:text-7xl leading-tight text-gray-300 text-block drop-shadow-lg relative z-40 mt-14 mr-1"
        style={{ fontFamily: "Bebas Neue", y: titleY }}
        id="top"
      >
        <h1 data-aos="fade-left" data-aos-delay="300">
          National
          <br /> Educator
          <br /> Awards
          <br /> 2025
        </h1>
        {/* <ShinyText
          data-aos-delay="300"
          text="calling for applications soon..."
          isHidden={true}
        /> */}
      </motion.div>

      <div className="absolute inset-0 z-30 bg-slate-900/40"></div>

      <motion.div
        style={{ y: awardY }}
        id="logoland"
        className="inset-0"
        transition={{
          delay: 0,
        }}
      >
        <img
          data-aos="fade-right"
          src="/assets/img/award.webp"
          data-aos-delay="300"
        />
      </motion.div>

      <motion.div
        className="description-block inset-0 absolute z-40"
        style={{ y: descriptionY }}
      >
        <p
          className="text-sm md:text-xl leading-8 text-gray-200 mb-4 text-center md:text-left max-w-xl drop-shadow-lg lg:pt-28"
          data-aos="fade-down"
          data-aos-delay="350"
        >
          Welcome to the National Educator Awards (NEA), where excellence in
          education is celebrated and recognized.
          <br />
          <br />
          NEA is a prestigious initiative dedicated to honoring outstanding
          <br />
          educators across Sri Lanka who have made remarkable
          <br />
          contributions totheir respective academic disciplines.
          <br />
          <br />
          Our mission is to shine a spotlight on the extraordinary
          <br />
          individuals who shape the future of education in our nation.
          <br />
          <br />
        </p>
        <div className="flex gap-10">
          <button
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-once="true"
            onClick={handleApplyClick}
            className="mx-auto lg:mx-0 rounded-sm bg-orange-500 px-6 lg:px-10 py-3 text-xs lg:text-sm font-semibold text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            APPLY NOW
          </button>
          <HashLink
            to="/#aboutus"
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-once="true"
            className="mx-auto lg:mx-0 rounded-sm bg-orange-400/20 border-orange-500 border-2 px-6 lg:px-10 py-3 text-xs lg:text-sm font-semibold text-white shadow-sm hover:bg-orange-400/70 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            FIND OUT MORE
          </HashLink>
          {/* <Link
            data-aos="fade-up"
            data-aos-delay="400"
            data-aos-once="true"
            to="/judgestable"
            className="fancy w-[155px] lg:w-[200px] rounded-sm bg-orange-500 px-6 lg:px-10 py-3 shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <span className="w-fit px-2 lg:px-8 py-3 mx-auto lg:mx-0 text-xs lg:text-sm font-semibold text-white">
              JUDGING SCHEDULE
            </span>
          </Link> */}
        </div>
        {/* <motion.div
          className="text-xl md:text-4xl leading-tight text-gray-300 text-block drop-shadow-lg mt-2"
          style={{ fontFamily: "Bebas Neue", y: titleY }}
        >
          <ShinyText text="calling for applications soon..." isHidden={false} />
        </motion.div> */}
      </motion.div>

      <motion.div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: `url(/assets/img/sky.webp)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
          y: backgroundY,
        }}
      />
      <div
        className="absolute inset-0 z-20"
        style={{
          backgroundImage: `url(/assets/img/mountains2.webp)`,
          backgroundPosition: "bottom",
          backgroundSize: "cover",
        }}
      />
      {isOverlayOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black bg-opacity-60 backdrop-blur-md"></div>
          <div className="relative bg-black/30 rounded-md p-6 shadow-lg z-60">
            <button
              onClick={() => setOverlayOpen(false)}
              className="mt-4 text-sm text-gray-400 absolute top-0 right-4 font-sans"
            >
              X
            </button>
            <h2 className="text-xl font-bold mb-4 text-white">
              Select a Category
            </h2>
            <div className="flex gap-4">
              <button
                onClick={() => handleSelection("/general-edu")}
                className="rounded-sm bg-orange-500 px-6 py-3 text-xs uppercase font-semibold text-white shadow-sm hover:bg-orange-400"
              >
                General Education
              </button>
              <button
                onClick={() => handleSelection("/higher-edu")}
                className="rounded-sm bg-orange-500 px-6 py-3 text-xs uppercase font-semibold text-white shadow-sm hover:bg-orange-400"
              >
                Higher Education
              </button>
            </div>
            <button
              onClick={() => setOverlayOpen(false)}
              className="mt-4 text-sm text-gray-400 underline"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
