import React, { useEffect } from "react";
import { HomeHeaderSection } from "../components/home-header-section";
import { Educator } from "../components/educator";
import { AwardCategories } from "../components/award-categories";
import { Judges } from "../components/judges";
import ParallaxBlock from "../components/parallax";
import { JudgeSlider } from "../components/judgeSlider";
import ParallaxNew from "../components/parallaxNew";
import { Aboutus } from "../components/aboutus";
import { Team } from "../components/team";
import { Steering } from "../components/steering";
import { ImageSlider } from "../components/eventImageSlider";
import { WinnerSlider } from "../components/winnersSlider";
import WelcomeSection from "../components/welcomeSection";

export const Home = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <ParallaxNew />
      <WelcomeSection />
      <ImageSlider />
      <WinnerSlider />
      <AwardCategories />
      {/* <JudgeSlider /> */}
      <Aboutus />
      <Steering />
      <Team />
    </>
  );
};
