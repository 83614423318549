import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { Terms } from "../components/terms";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import FullScreenLoader from "../components/loader";
import queryString from "query-string";

export const Apply = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    university: "",
    specialization: "",
    email: "",
    phone: "",
    agreementAccepted: false,
  });
  const [formDataSc, setFormDataSc] = useState({
    nic: "",
    title: "",
    full_name: "",
    phone: "",
    whatsapp_phone: "",
    email: "",
    school_province: "",
    school_education_zone: "",
    school_of_employement: "",
    award_category: "",
    agreementAccepted: false,
  });
  const [isOther, setIsOther] = useState(false);
  const [phoneError, setPhoneError] = useState("");
  const [isPaymentSelect, setIsPaymentSelect] = useState(false);
  const [isBankDeposit, setIsBankDeposit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isRecieptSubmit, setIsRecieptSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);

  const location = useLocation();
  const { state, orderidparam } = useParams();
  const queryParams = queryString.parse(location.search);
  const resultIndicator = queryParams.resultIndicator;

  console.log(state, orderidparam, resultIndicator);

  const handleFileSelect = (e) => {
    const maxSizeBytes = 5 * 1024 * 1024; // 5MB in bytes
    const { name, size } = e.target.files[0];
    if (size > maxSizeBytes) {
      alert("File size is too large. Max size is 5MB.");
      return;
    } else if (
      !name.endsWith(".pdf") &&
      !name.endsWith(".png") &&
      !name.endsWith(".jpg") &&
      !name.endsWith(".jpeg")
    ) {
      alert(
        "Please upload a valid file type. Only PDF, PNG, JPG, JPEG files are allowed."
      );
      return;
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    // if (name === "university" && value === "Other") {
    //   setIsOther(true);
    // } else {
    // }
    if (type === "he") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (type === "sc") {
      setFormDataSc({
        ...formDataSc,
        [name]: value,
      });
    }
  };

  const handlePhoneChange = (value, type) => {
    if (type === "he") {
      setFormData({
        ...formData,
        phone: value,
      });
    } else if (type === "sc") {
      setFormDataSc({
        ...formDataSc,
        phone: value,
      });
    } else if (type === "sc_w") {
      setFormDataSc({
        ...formDataSc,
        whatsapp_phone: value,
      });
    }
  };

  const handleAgreementChange = (e, type) => {
    const { name, checked } = e.target;
    if (type === "he") {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else if (type === "sc") {
      setFormDataSc({
        ...formDataSc,
        [name]: checked,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (formData.agreementAccepted) {
    // Handle form submission here
    setIsLoading(true);
    const orderid = generateOrderId();
    let data = {};
    if (selectedCat === "he") {
      data = { orderid: orderid, category: "Higher Education", ...formData };
    } else if (selectedCat === "sc") {
      data = { orderid: orderid, category: "School Category", ...formDataSc };
    } else {
      console.log("Please select a category.");
      return null;
    }
    axios
      .post(
        `https://qf858c2ct0.execute-api.ap-south-1.amazonaws.com/save-user`,
        data
      )
      .then((response) => {
        // console.log(response);
        setIsPaymentSelect(true);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error initiating payment:", error);
      });

    // Scroll to top of page
    window.scrollTo({ top: 0, behavior: "smooth" });
    // } else {
    // Show an error message or prevent form submission
    //   console.log("Please accept the terms and conditions.");
    // }
  };

  const onReceiptSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formDataAws = new FormData();
    formDataAws.append("file", selectedFile);
    formDataAws.append("orderid", orderId);
    formDataAws.append(
      "formdata",
      selectedCat === "sc"
        ? JSON.stringify(formDataSc)
        : JSON.stringify(formData)
    );
    const baseURL = "https://qf858c2ct0.execute-api.ap-south-1.amazonaws.com";

    axios
      .post(`${baseURL}/file-upload`, formDataAws, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        setIsLoading(false);
        setIsRecieptSubmit(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error uploading receipt:", error);
      });
  };

  const generateOrderId = () => {
    const date = new Date().toISOString().slice(5, 10); // Get the current date (MM-DD)
    const currentDate = new Date();
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const randomNum = Math.floor(Math.random() * 10000); // Add randomness for uniqueness
    const orderId = `NEA-${date}-${hours}-${minutes}-${randomNum}`;
    setOrderId(orderId);
    return orderId;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    if ((state && orderidparam) || resultIndicator) {
      axios
        .patch(
          `https://qf858c2ct0.execute-api.ap-south-1.amazonaws.com/update-payment`,
          {
            orderid: orderidparam,
            result_indicator: resultIndicator ? resultIndicator : "",
            state: state,
          }
        )
        .then((response) => {
          console.log(response);
          sessionStorage.clear();
        })
        .catch((error) => {
          console.error("Error updating payment status:", error);
          sessionStorage.clear();
        });
    }
  }, []);

  useEffect(() => {
    const category = params.get("category");
    const award = params.get("award");

    if (category === "higher-education") {
      setSelectedCat("he");
    }
    if (category === "general-education") {
      setSelectedCat("sc");
    }

    if (
      award === "Impactful Teaching" ||
      award === "Community Engagement" ||
      award === "Educational Innovation"
    ) {
      setFormDataSc({
        ...formDataSc,
        award_category: award,
      });
    } else {
      setFormData({
        ...formData,
        specialization: award,
      });
    }
  }, [search]);

  const startPaymentRequest = async () => {
    setIsLoading(true);
    const requestData = {
      orderid: orderId,
      amount: selectedCat === "sc" ? 1000 : 5000,
      currency: "LKR",
      description: "national education award 2024 - application fee",
      name: selectedCat === "sc" ? formDataSc.full_name : formData.name,
      email: selectedCat === "sc" ? formDataSc.email : formData.email,
    };

    axios
      .post(
        `https://qf858c2ct0.execute-api.ap-south-1.amazonaws.com/get-session-id`,
        requestData
      )
      .then((response) => {
        const sessionData = response.data.data;
        setIsLoading(false);
        console.log(sessionData);
        // Extract the session ID from the response
        const session_id = sessionData.id;

        // Set the session ID in the component's state
        setSessionId(session_id);
        console.log(session_id);
        // Call Checkout.configure with the session ID and any other necessary configuration
        window &&
          window.Checkout.configure({
            session: {
              id: session_id,
            },
          });

        // Start the payment process
        window && window.Checkout.showEmbeddedPage("#embed-target");
      })
      .catch((error) => {
        setIsLoading(false);
        console.error("Error initiating payment:", error);
      });
  };

  return (
    <div className="apply-bg-color w-full h-full min-h-screen py-10 lg:py-6 relative">
      {isLoading && <FullScreenLoader />}
      <img
        src={
          selectedCat === "he"
            ? "/assets/img/higher-award.webp"
            : selectedCat === "sc"
            ? "/assets/img/general-award.webp"
            : "/assets/img/award.webp"
        }
        className={
          selectedCat === "he" || selectedCat === "sc"
            ? "absolute brightness-50 inset-0 w-full h-full  object-cover object-[90%_16%]"
            : "fixed z-5 w-[300px] right-[25%]"
        }
      />
      <h1
        className="text-3xl font-bold text-[#dd7538] sm:text-5xl capitalize tracking-wider px-4 lg:px-0 section-title relative mx-auto w-fit z-20"
        style={{ fontFamily: "Poppins" }}
        data-aos="fade-down"
        data-aos-delay="100"
      >
        {state === "cancel"
          ? "PAYMENT CANCELLED"
          : state === "timeout"
          ? "TIMEOUT"
          : state === "success"
          ? "PAYMENT SUCCESS"
          : "Apply Now"}
      </h1>
      <div className="max-w-xl mx-auto my-10 px-4 relative z-20">
        {state === "cancel" ? (
          <CancelMessage />
        ) : state === "timeout" ? (
          <TimeoutMessage />
        ) : state === "success" ? (
          <SuccesstMessage />
        ) : !isPaymentSelect ? (
          <div>
            <div className="category-buttons">
              {selectedCat !== "sc" && (
                <button
                  data-aos="fade-up"
                  data-aos-delay="600"
                  data-aos-once="true"
                  type="button"
                  className={`he-cat-btn ${selectedCat === "he" && "selected"}`}
                  onClick={() => {
                    setSelectedCat("he");
                  }}
                >
                  Higher Education
                </button>
              )}
              {selectedCat !== "he" && (
                <button
                  data-aos="fade-up"
                  data-aos-delay="700"
                  data-aos-once="true"
                  type="button"
                  className={`sc-cat-btn ${selectedCat === "sc" && "selected"}`}
                  onClick={() => {
                    setSelectedCat("sc");
                  }}
                >
                  General Education
                </button>
              )}
            </div>
            {selectedCat === "he" ? (
              <form onSubmit={handleSubmit} className="apply-form">
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="100"
                >
                  <label
                    htmlFor="title"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Title
                  </label>
                  <select
                    id="title"
                    name="title"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "he")}
                    value={formData.title}
                    required
                  >
                    <option value="">Select Title</option>
                    <option value="Prof">Prof</option>
                    <option value="Dr">Dr</option>
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                  </select>
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="200"
                >
                  <label
                    htmlFor="name"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "he")}
                    value={formData.name}
                    required
                  />
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="300"
                >
                  <label
                    htmlFor="university"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    University
                  </label>
                  <input
                    type="text"
                    id="university"
                    name="university"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "he")}
                    value={formData.university}
                    required
                  />
                  {/* <select
              id="university"
              name="university"
              className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
              onChange={(e)=>handleInputChange(e, 'he')}
              value={formData.university}
              required
            >
              <option value="">Select University</option>
              <option value="Colombo Moratuwa">Colombo Moratuwa</option>
              <option value="Peradeniya">Peradeniya</option>
              <option value="IIT">IIT</option>
              <option value="Rajarata University">Rajarata University</option>
              <option value="Kelaniya">Kelaniya</option>
              Add more universities here
              <option value="Other">Other</option>
            </select> */}
                </div>
                {isOther && (
                  <div
                    className="mb-4"
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay="100"
                  >
                    <label
                      htmlFor="customUniversity"
                      className="block text-gray-300 text-sm font-semibold"
                    >
                      Other University
                    </label>
                    <input
                      type="text"
                      id="customUniversity"
                      name="university"
                      className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                      onChange={(e) => handleInputChange(e, "he")}
                      value={formData.university}
                      required
                    />
                  </div>
                )}
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="400"
                >
                  <label
                    htmlFor="specialization"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Specialization
                  </label>
                  <select
                    id="specialization"
                    name="specialization"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "he")}
                    value={formData.specialization}
                    required
                  >
                    <option value="">Select Specialization</option>
                    <option value="Applied Sciences">Applied Sciences</option>

                    <option value="Business & Management">
                      Business & Management
                    </option>
                    <option value="Computing">Computing</option>
                    <option value="Education">Education</option>
                    <option value="Engineering">Engineering</option>
                    <option value="Social Sciences">Social Sciences</option>
                    <option value="Language and Literature">
                      Language and Literature
                    </option>
                    <option value="Law">Law</option>
                    <option value="Mathematics & Statistics">
                      Mathematics & Statistics
                    </option>
                    <option value="Nursing and Health Sciences">
                      Nursing and Health Sciences
                    </option>
                    <option value="Psychology and Counselling">
                      Psychology and Counselling
                    </option>
                  </select>
                </div>

                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <label
                    htmlFor="email"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "he")}
                    value={formData.email}
                    required
                  />
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="600"
                >
                  <label
                    htmlFor="phone"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Phone
                  </label>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      id: "phone",
                    }}
                    className="apply-field-tel mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    inputStyle={{ width: "100%" }}
                    placeholder="Enter phone number"
                    value={formData.phone}
                    onChange={(e) => handlePhoneChange(e, "he")}
                    enableSearch={true}
                    specialLabel=""
                    country={"lk"}
                  />
                  <div
                    className="my-4"
                    data-aos="fade-up"
                    data-aos-once="true"
                    data-aos-delay="800"
                  >
                    {/* Text area for agreement details */}
                    <div className="text-gray-300 text-sm font-semibold mb-2">
                      Agreement Details
                    </div>
                    <div className="apply-field border-gray-300 border rounded-md p-2">
                      {/* Use a text area or div (read-only) */}
                      <div className="w-full rounded-md h-[300px] overflow-y-scroll overflow-x-hidden">
                        <Terms type="he" />
                      </div>
                      {/* If a div is used instead of a text area, match the styles */}
                      {/* <div className="w-full bg-gray-100 rounded-md p-2">
                Include your agreement details here with clickable links.
              </div> */}
                    </div>
                  </div>
                </div>

                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  {/* Checkbox for agreement acceptance */}
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="agreementAccepted"
                      checked={formData.agreementAccepted}
                      onChange={(e) => handleAgreementChange(e, "he")}
                      className="form-checkbox border-gray-300 text-[#dd7538] focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                      required
                    />
                    <span className="ml-2 text-gray-300 text-sm">
                      I agree to the terms and conditions
                    </span>
                  </label>
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  <button
                    type="submit"
                    className="px-4 py-2 mt-4 bg-[#dd7538] text-white rounded-sm hover:bg-[#ff914d] focus:outline-none focus:bg-[#ff914d]"
                  >
                    Submit and Pay
                  </button>
                </div>
              </form>
            ) : selectedCat === "sc" ? (
              <form onSubmit={handleSubmit} className="apply-form">
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="100"
                >
                  <label
                    htmlFor="nic"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    NIC
                  </label>
                  <input
                    type="text"
                    id="nic"
                    name="nic"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "sc")}
                    value={formDataSc.nic}
                    required
                  />
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="200"
                >
                  <label
                    htmlFor="title"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Title
                  </label>
                  <select
                    id="title"
                    name="title"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "sc")}
                    value={formDataSc.title}
                    required
                  >
                    <option value="">Select Title</option>
                    <option value="Prof">Prof</option>
                    <option value="Dr">Dr</option>
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                  </select>
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="300"
                >
                  <label
                    htmlFor="full_name"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    id="full_name"
                    name="full_name"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "sc")}
                    value={formDataSc.full_name}
                    required
                  />
                </div>

                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="800"
                >
                  <label
                    htmlFor="phone"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Contact Number
                  </label>
                  <PhoneInput
                    inputProps={{
                      name: "phone",
                      required: true,
                      id: "phone",
                    }}
                    className="apply-field-tel mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    inputStyle={{ width: "100%" }}
                    placeholder="Enter phone number"
                    value={formDataSc.phone}
                    onChange={(e) => handlePhoneChange(e, "sc")}
                    enableSearch={true}
                    specialLabel=""
                    country={"lk"}
                  />
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="800"
                >
                  <label
                    htmlFor="whatsapp_phone"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    WhatsApp Number
                  </label>
                  <PhoneInput
                    inputProps={{
                      name: "whatsapp_phone",
                      required: false,
                      id: "whatsapp_phone",
                    }}
                    className="apply-field-tel mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    inputStyle={{ width: "100%" }}
                    placeholder="Enter WhatsApp number"
                    value={formDataSc.whatsapp_phone}
                    onChange={(e) => handlePhoneChange(e, "sc_w")}
                    enableSearch={true}
                    specialLabel=""
                    country={"lk"}
                  />
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="900"
                >
                  <label
                    htmlFor="email"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "sc")}
                    value={formDataSc.email}
                    required
                  />
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <label
                    htmlFor="school_province"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Province of Your School
                  </label>
                  <select
                    id="school_province"
                    name="school_province"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "sc")}
                    value={formDataSc.school_province}
                    required
                  >
                    <option value="">Select a Province</option>
                    <option value="Western Province">Western Province</option>
                    <option value="Central Province">Central Province</option>
                    <option value="Eastern Province">Eastern Province</option>
                    <option value="Northern Province">Northern Province</option>
                    <option value="North Central Province">
                      North Central Province
                    </option>
                    <option value="North Western Province">
                      North Western Province
                    </option>
                    <option value="Sabaragamuwa Province">
                      Sabaragamuwa Province
                    </option>
                    <option value="Southern Province">Southern Province</option>
                    <option value="Uva Province">Uva Province</option>
                  </select>
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="600"
                >
                  <label
                    htmlFor="school_education_zone"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Education Zone of Your School
                  </label>
                  <input
                    type="text"
                    id="school_education_zone"
                    name="school_education_zone"
                    placeholder="Leave empty if not applicable"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "sc")}
                    value={formDataSc.school_education_zone}
                  />
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="700"
                >
                  <label
                    htmlFor="school_of_employement"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    School of Employment
                  </label>
                  <input
                    type="text"
                    id="school_of_employement"
                    name="school_of_employement"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "sc")}
                    value={formDataSc.school_of_employement}
                    required
                  />
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="800"
                >
                  <label
                    htmlFor="award_category"
                    className="block text-gray-300 text-sm font-semibold"
                  >
                    Award Category
                  </label>
                  <select
                    id="award_category"
                    name="award_category"
                    className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                    onChange={(e) => handleInputChange(e, "sc")}
                    value={formDataSc.award_category}
                    required
                  >
                    <option value="">Select Award Category</option>
                    <option value="Impactful Teaching">
                      Impactful Teaching
                    </option>
                    <option value="Community Engagement">
                      Community Engagement
                    </option>
                    <option value="Educational Innovation">
                      Educational Innovation
                    </option>
                  </select>
                </div>
                <div
                  className="my-4"
                  data-aos="fade-up"
                  data-aos-once="true"
                  data-aos-delay="1000"
                >
                  {/* Text area for agreement details */}
                  <div className="text-gray-300 text-sm font-semibold mb-2">
                    Agreement Details
                  </div>
                  <div className="apply-field border-gray-300 border rounded-md p-2">
                    {/* Use a text area or div (read-only) */}
                    <div className="w-full rounded-md h-[300px] overflow-y-scroll overflow-x-hidden">
                      <Terms type="sc" />
                    </div>
                    {/* If a div is used instead of a text area, match the styles */}
                    {/* <div className="w-full bg-gray-100 rounded-md p-2">
                Include your agreement details here with clickable links.
              </div> */}
                  </div>
                </div>

                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-delay="300"
                  data-aos-once="true"
                >
                  {/* Checkbox for agreement acceptance */}
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="agreementAccepted"
                      checked={formDataSc.agreementAccepted}
                      onChange={(e) => handleAgreementChange(e, "sc")}
                      className="form-checkbox border-gray-300 text-[#dd7538] focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50"
                      required
                    />
                    <span className="ml-2 text-gray-300 text-sm">
                      I agree to the terms and conditions
                    </span>
                  </label>
                </div>
                <div
                  className="mb-4"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  data-aos-once="true"
                >
                  <button
                    type="submit"
                    className="px-4 py-2 mt-4 bg-[#dd7538] text-white rounded-sm hover:bg-[#ff914d] focus:outline-none focus:bg-[#ff914d]"
                  >
                    Submit and Pay
                  </button>
                </div>
              </form>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <button
              onClick={() => setIsBankDeposit(!isBankDeposit)}
              className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50 hover:bg-orange-400"
            >
              Bank Deposit
            </button>
            {isBankDeposit && (
              <div className="py-10">
                {!isRecieptSubmit ? (
                  <div className="bank-detail-form">
                    <div className="mb-4">
                      <p className="text-white ">
                        <b>Amount - </b>
                        {selectedCat === "sc"
                          ? "LKR 1000.00"
                          : "LKR 5000.00"}{" "}
                      </p>
                      <p className="text-white ">
                        <b>Bank - </b>Hatton National Bank{" "}
                      </p>
                      <p className="text-white ">
                        <b>Account No. - </b> 156010007350{" "}
                      </p>
                      <p className="text-white ">
                        <b>Branch – </b>Malabe{" "}
                      </p>
                      <p className="text-white ">
                        <b>Account Name – </b>Sri Lanka Institute of Information
                        Technology (Gte) Ltd.{" "}
                      </p>
                      <p className="text-white ">
                        <b>Swift Code – </b>HBLILKLX
                      </p>
                      <br />
                      <p className="text-white ">
                        <b>Reference Code – </b>
                        <span className="text-orange-500 font-semibold">
                          {orderId}
                        </span>
                      </p>
                    </div>
                    <p className="text-orange-500 font-semibold">
                      Please use the above "Reference Code" in the
                      Description/Remark field when you are doing the payment.
                    </p>
                    <p className="text-white pt-4 pb-2 font-semibold">
                      Please upload your payment receipt after completing the
                      transaction.
                    </p>
                    <form
                      onSubmit={(e) => {
                        onReceiptSubmit(e);
                      }}
                    >
                      <label
                        className="apply-field mt-1 block w-full rounded-md border-orange-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50 hover:bg-orange-400"
                        style={{ backgroundColor: "rgba(221, 117, 56, 0.5)" }}
                      >
                        <input
                          type="file"
                          onChange={handleFileSelect}
                          className="hidden"
                          accept="image/*,application/pdf"
                          required
                        />
                        <div className="flex flex-row justify-between items-center cursor-pointer">
                          <p>Select File</p>
                          {selectedFile && (
                            <p
                              className="text-white w-full"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              {selectedFile.name}
                            </p>
                          )}
                        </div>
                      </label>
                      <button
                        type="submit"
                        disabled={!selectedFile || isLoading}
                        className="px-4 py-2 mt-4 bg-[#dd7538] text-white rounded-sm hover:bg-[#ff914d] focus:outline-none focus:bg-[#ff914d]"
                      >
                        {isLoading ? (
                          <div className="flex flex-row items-center">
                            Submit Receipt
                            <img
                              width={30}
                              height={30}
                              priority
                              src="/assets/img/loading.svg"
                              alt="Follow us on Twitter"
                            />
                          </div>
                        ) : (
                          "Submit Receipt"
                        )}
                      </button>
                    </form>
                  </div>
                ) : (
                  <>
                    <p
                      className="text-white pt-4 pb-2 font-semibold text-center"
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-once="true"
                    >
                      Thank you for submitting your payment receipt!
                      <br />
                      Our team will review it and send a confirmation to your
                      email shortly. <br />
                      <br />
                      Please follow the instructions in your email to submit
                      your application for National Educator Awards 2024.
                      <br />
                      Please ensure to check both your inbox and spam folder for
                      our response. <br />
                      If you have any questions or need further assistance, feel
                      free to contact our support team through phone
                      <a href="tel:0117543945" className="text-[#dd7538]">
                        {" "}
                        0117543945
                      </a>{" "}
                      /
                      <a href="tel:+94773191815" className="text-[#dd7538]">
                        {" "}
                        0773191815
                      </a>{" "}
                      or send us an email to{" "}
                      <a
                        href="mailto:info@nationaleducatorawards.lk"
                        className="text-[#dd7538]"
                      >
                        info@nationaleducatorawards.lk
                      </a>
                    </p>
                  </>
                )}
              </div>
            )}
            <button
              disabled={sessionId || isRecieptSubmit}
              onClick={() => {
                startPaymentRequest();
              }}
              className="apply-field mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#dd7538] focus:ring focus:ring-[#dd7538] focus:ring-opacity-50 hover:bg-orange-400"
            >
              Online Payment
            </button>
            <div
              className={`w-full p-6  mt-4 rounded-lg ${
                sessionId && "bg-white"
              }`}
            >
              <div className="w-full" id="embed-target"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const CancelMessage = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <p
        className="text-white pt-4 pb-2 font-semibold text-center"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
      >
        Payment process cancelled!
        <br />
        <br />
        If you want to try again, kindly Start process from beginning by
        clicking below button. Or if you need any assistant reach out to us via
        <a href="tel:0117543945" className="text-[#dd7538]">
          {" "}
          0117543945
        </a>{" "}
        /
        <a href="tel:+94773191815" className="text-[#dd7538]">
          {" "}
          0773191815
        </a>{" "}
        or send us an email to{" "}
        <a
          href="mailto:info@nationaleducatorawards.lk"
          className="text-[#dd7538]"
        >
          info@nationaleducatorawards.lk
        </a>
      </p>
      <Link
        to={"/apply"}
        className="px-4 py-2 mt-4 bg-[#dd7538] text-white rounded-sm hover:bg-[#ff914d] focus:outline-none focus:bg-[#ff914d]"
      >
        Start From Beginnning
      </Link>
    </div>
  );
};

export const TimeoutMessage = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <p
        className="text-white pt-4 pb-2 font-semibold text-center"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
      >
        Payment process timeout!
        <br />
        <br />
        If you want to try again, kindly Start process from beginning by
        clicking below button. Or if you need any assistant reach out to us via
        <a href="tel:0117543945" className="text-[#dd7538]">
          {" "}
          0117543945
        </a>{" "}
        /
        <a href="tel:+94773191815" className="text-[#dd7538]">
          {" "}
          0773191815
        </a>{" "}
        or send us an email to{" "}
        <a
          href="mailto:info@nationaleducatorawards.lk"
          className="text-[#dd7538]"
        >
          info@nationaleducatorawards.lk
        </a>
      </p>
      <Link
        to={"/apply"}
        className="px-4 py-2 mt-4 bg-[#dd7538] text-white rounded-sm hover:bg-[#ff914d] focus:outline-none focus:bg-[#ff914d]"
      >
        Start From Beginnning
      </Link>
    </div>
  );
};

export const SuccesstMessage = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <p
        className="text-white pt-4 pb-2 font-semibold text-center"
        data-aos="fade-up"
        data-aos-delay="100"
        data-aos-once="true"
      >
        Thank you for your payment!
        <br />
        We have sent a confirmation email to your registered e-mail address.
        <br />
        <br />
        Please follow the instructions in your email to submit your application
        for National Educator Awards 2024.
        <br />
        Please ensure to check both your inbox and spam folder for our response.
        <br />
        If you have any questions or need further assistance, feel free to
        contact our support team through phone
        <a href="tel:+94117543945" className="text-[#dd7538]">
          {" "}
          0117543945
        </a>{" "}
        /
        <a href="tel:+94773191815" className="text-[#dd7538]">
          {" "}
          0773191815
        </a>{" "}
        or send us an email to{" "}
        <a
          href="mailto:info@nationaleducatorawards.lk"
          className="text-[#dd7538]"
        >
          info@nationaleducatorawards.lk
        </a>
      </p>
      <Link
        to={"/"}
        className="px-4 py-2 mt-4 bg-[#dd7538] text-white rounded-sm hover:bg-[#ff914d] focus:outline-none focus:bg-[#ff914d]"
      >
        Go to Home
      </Link>
    </div>
  );
};
