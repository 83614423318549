import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBrain,
  faBriefcase,
  faBuilding,
  faCalculator,
  faComputer,
  faHospital,
  faMicroscope,
  faRuler,
  faScaleBalanced,
  faStethoscope,
  faUserGraduate,
  faWrench,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  ArrowRight,
  FileText,
  Filter,
  Users,
  Trophy,
  ArrowDown,
} from "lucide-react";
import { motion } from "framer-motion";
import WinnerCard from "../components/common/winnerCard";
import { awards2023 } from "../lib/awardsWinners";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/ui/Button";

export const HigherEducation = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const steps = [
    {
      title: "Call for Applications",
      description: "Open submissions for award applications.",
      icon: FileText,
    },
    {
      title: "Shortlisting Applications",
      description: "Shortlist based on eligibility criteria.",
      icon: Filter,
    },
    {
      title: "Evaluations ",
      description: "Evaluation by a panel of three judges.",
      icon: Users,
    },
    {
      title: "Announcing the Winners",
      description: "Selection and announcement of award recipients.",
      icon: Trophy,
    },
  ];
  const categories = [
    { name: "Applied Sciences", icon: faMicroscope },

    { name: "Business & Management", icon: faBriefcase },
    { name: "Computing", icon: faComputer },
    { name: "Education", icon: faUserGraduate },
    { name: "Engineering", icon: faWrench },
    { name: "Social Sciences", icon: faEarthAmericas },
    { name: "Language and Literature", icon: faBook },
    { name: "Law", icon: faScaleBalanced },
    { name: "Mathematics & Statistics", icon: faCalculator },
    { name: "Nursing and Health Sciences", icon: faStethoscope },
    { name: "Psychology and Counselling", icon: faBrain },
  ];

  return (
    <>
      <section className="relative">
        {/* Background Image with Overlay */}
        <div className="absolute inset-0">
          <img
            src="/assets/img/higher-award.webp"
            alt="Background"
            className="absolute brightness-50 inset-0 w-full h-full  object-cover object-[50%_16%]"
          />
          <div className="absolute inset-0 bg-black/50" />
          {/* Overlay for better readability */}
        </div>

        {/* Content */}
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="max-w-4xl mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 tracking-tight">
                  <span className="text-orange-500">Higher</span> Education
                  Awards
                </h1>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                transition={{ duration: 0.8, delay: 0.5 }}
              >
                <p className="text-md lg:text-lg md:text-xl text-gray-100 max-w-3xl mx-auto leading-relaxed">
                  Launched as the first category of awards under NEA in 2023,
                  the higher education awards category recognizes academics who
                  have excelled in teaching, research, and the contributions to
                  institutional, national & international developments. Higher
                  Education awards category felicitate the academics with a
                  gold, silver and bronze awards under selected disciplines.
                </p>
              </motion.div>
            </div>
          </div>

          <div className="max-w-7xl mx-auto flex flex-wrap justify-center gap-4">
            {categories.map((award, index) => (
              <div
                key={index}
                className="w-full sm:w-1/2 lg:w-[30%] bg-gray-900 hover:bg-gray-950 gap-1 p-4 rounded-lg flex items-center justify-between space-x-4 border-[1px] border-orange-600"
              >
                <div className="flex-shrink-0">
                  <FontAwesomeIcon
                    icon={award.icon}
                    size="xl"
                    className="icon text-gray-100"
                  />
                </div>
                <h3 className="text-gray-200 text-sm sm:text-base font-semibold flex-grow text-left max-w-[35%]">
                  {award.name}
                </h3>
                <Link
                  to={`/apply?category=${encodeURIComponent(
                    "higher-education"
                  )}&award=${encodeURIComponent(award.name)}`}
                  className="border-2 font-semibold border-transparent bg-orange-600 text-white px-4 py-2 rounded-sm hover:bg-slate-800 hover:text-orange-400 hover:border-2 hover:border-orange-400 transition-colors duration-300 text-sm text-center"
                >
                  Proceed to Apply
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-slate-900 py-5 px-4 sm:px-6 lg:px-8">
        <div className="max-w-full  mx-auto">
          <div className="text-center mb-16">
            <div className="w-full h-full py-5 lg:py-6 relative">
              <h1
                className="text-3xl font-bold text-[#dd7538] sm:text-5xl capitalize tracking-wider px-4 lg:px-0 section-title relative mx-auto w-fit z-20"
                style={{ fontFamily: "Poppins" }}
                data-aos="fade-down"
                data-aos-delay="100"
              >
                PAST WINNERS
              </h1>
              <div className="flex max-w-screen-2xl mt-10 flex-col md:flex-row mx-auto">
                <div className="flex flex-col w-full py-4 px-8 gap-8 items-center justify-center">
                  <div className="gold ">
                    {/* <h1 className="font-semibold text-left text-2xl md:text-[28px] text-[#e99e00]">
                      Gold Winners
                    </h1> */}
                    <div className="flex justify-center lg:justify-start items-stretch flex-row flex-wrap w-full gap-8 mt-8">
                      {awards2023
                        .filter((data) => data.award === "gold")
                        .slice(0, 3)
                        .map((data, index) => {
                          if (index === 3) {
                            return (
                              <WinnerCard
                                data={data}
                                index={index}
                                showMore={true} // Add a prop to indicate this card should show the button
                                key={index}
                              />
                            );
                          } else {
                            return <WinnerCard data={data} index={index} />;
                          }
                        })}
                      <div className="  px-4 mb-8 flex items-center justify-center">
                        <Button
                          data-aos="fade-up"
                          data-aos-delay={100 + 50 * 4}
                          variant="outline"
                          size="default"
                          className="border-2 border-transparent bg-orange-600 text-white rounded-sm hover:bg-slate-800 hover:text-orange-400 hover:border-2 hover:border-orange-400 transition-colors duration-300 text-base whitespace-nowrap font-semibold"
                          onClick={() => navigate("/winners")}
                        >
                          Show More
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-slate-900 text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1
            className="text-3xl font-bold text-[#dd7538] sm:text-5xl capitalize tracking-wider px-4 lg:px-0 section-title relative mx-auto w-fit z-20 mb-20"
            style={{ fontFamily: "Poppins" }}
            data-aos="fade-down"
            data-aos-delay="100"
          >
            How We Process
          </h1>
          <div
            className="grid grid-cols-1 md:grid-cols-4 gap-8 px-2"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="relative"
              >
                <div className="bg-gray-800 rounded-lg p-6 h-full flex flex-col items-center text-center transition-all duration-300 hover:shadow-lg hover:shadow-orange-500/20 group">
                  <div className="w-12 h-12 bg-orange-500 rounded-full flex items-center justify-center mb-4 transition-transform duration-300 group-hover:scale-110">
                    <step.icon className="w-6 h-6 text-black" />
                  </div>
                  <div className="absolute -top-3 -left-3 w-8 h-8 bg-orange-500 rounded-full flex items-center justify-center text-black font-bold">
                    {index + 1}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-gray-400">{step.description}</p>
                </div>
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 -right-4 transform -translate-y-1/2">
                    <ArrowRight className="w-8 h-8 text-orange-500" />
                  </div>
                )}
                {index < steps.length - 1 && (
                  <div className="md:hidden absolute -bottom-4 left-1/2 transform -translate-x-1/2">
                    <ArrowDown className="w-8 h-8 text-orange-500" />
                  </div>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
