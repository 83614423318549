export const Terms = ({ type }) => {
  if (type === "he") {
    return (
      <div>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "13.999999999999998pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            National Educator Awards – Instructions for Applicants
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Welcome to National Educator Awards, an initiative to recognize and
            reward outstanding educators of Sri Lanka who have made valuable and
            significant contributions to the development of the Sri Lankan
            community in diverse ways through their respective
            disciplines.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            National Educator Awards 2024 invites applications from outstanding
            educators in Sri Lanka under eleven (11) categories which are as
            follows:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Applied Sciences
              </span>
            </p>
          </li>

          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Business &amp; Management
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Computing
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Education
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Engineering
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Social Sciences
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Language and Literature
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Law
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Mathematics &amp; Statistics
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Nursing and Health Sciences
              </span>
            </p>
          </li>
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.2",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                Psychology and Counseling
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Eligibility Requirements:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: '"Noto Sans Symbols",sans-serif',
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The applicant must be an academic currently serving at or
                attached to a state or non-state higher education institution in
                Sri Lanka
              </span>
            </p>
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
                paddingInlineStart: "48px",
              }}
            >
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",
                  fontFamily: '"Courier New",monospace',
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.295",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Calibri,sans-serif",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Permanent&nbsp;
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",
                  fontFamily: '"Courier New",monospace',
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.295",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Calibri,sans-serif",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Temporary
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",
                  fontFamily: '"Courier New",monospace',
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.295",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Calibri,sans-serif",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Contract
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",
                  fontFamily: '"Courier New",monospace',
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.295",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Calibri,sans-serif",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Visiting
                  </span>
                </p>
              </li>
              <li
                dir="ltr"
                style={{
                  listStyleType: "disc",
                  fontSize: "11pt",
                  fontFamily: '"Courier New",monospace',
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre",
                }}
                aria-level={2}
              >
                <p
                  dir="ltr"
                  style={{
                    lineHeight: "1.295",
                    marginTop: "0pt",
                    marginBottom: "0pt",
                  }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Calibri,sans-serif",
                      color: "#000000",
                      backgroundColor: "transparent",
                      fontWeight: 400,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    Adjunct&nbsp;
                  </span>
                </p>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: '"Noto Sans Symbols",sans-serif',
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The applicant must be contributing to one of the thirteen
                academic disciplines pertaining to the Awards.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        {/* <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}>
      <li
        dir="ltr"
        style={{
          listStyleType: "disc",
          fontSize: "11pt",
          fontFamily: '"Noto Sans Symbols",sans-serif',
          color: "#000000",
          backgroundColor: "transparent",
          fontWeight: 400,
          fontStyle: "normal",
          fontVariant: "normal",
          textDecoration: "none",
          verticalAlign: "baseline",
          whiteSpace: "pre",
        }}
        aria-level={1}
      >
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The applicant must be a resident of Sri Lanka.
          </span>
        </p>
      </li>
    </ul> */}
        {/* <p>
      <br />
    </p> */}
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The applicant must obtain a minimum of 40 marks in total and a
                minimum of 10% from the maximum mark allotted for each category
                of marks to be eligible for an award.&nbsp;
              </span>
            </p>
          </li>
        </ul>

        <p>
          <br />
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                An individual may only submit an application under a single
                category.&nbsp;
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Application Submission Details:
          </span>
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: '"Noto Sans Symbols",sans-serif',
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The application and supporting documents should be
                submitted&nbsp;
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                on or before the 31st of May 2025
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: '"Noto Sans Symbols",sans-serif',
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                The application and supporting documents must be submitted
                online through the application portal . The link to the
                application portal will be sent via an email once the payment is
                submitted and approved. The evaluation criteria can be
                downloaded from this&nbsp;
              </span>
              <a
                href="https://docs.google.com/spreadsheets/d/1i_CedaHVAd8ysPWitHXXHE58LGdbdtKdDEMGwLWrQtM/export?format=xlsx"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Calibri,sans-serif",
                    color: "#edca11",
                    backgroundColor: "transparent",
                    fontWeight: 600,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    WebkitTextDecorationSkip: "none",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Link
                </span>
              </a>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                .
              </span>
            </p>
          </li>
        </ul>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            marginLeft: "36pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#ff0000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Hard copies or email applications will not be accepted. Once the
            application is submitted no modifications would be permitted.&nbsp;
          </span>
        </p>
        <p>
          <br />
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: '"Noto Sans Symbols",sans-serif',
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                marginTop: "0pt",
                marginBottom: "0pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                An application processing fee of Rs.
                {type === "he" ? "5000" : "1000"}
              </span>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                &nbsp;is payable at the time of submitting the application and
                the payment can be made online through the payment gateway
                provided on this site. Alternatively, the payment can be made to
                the following bank account through a bank deposit or an
                electronic transfer. &nbsp;
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            marginLeft: "36pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Bank: Hatton National Bank
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            marginLeft: "36pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Account No. 156010007350
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            marginLeft: "36pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Branch – Malabe
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            marginLeft: "36pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Account Name – Sri Lanka Institute of Information Technology (Gte)
            Ltd.
          </span>
        </p>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{
            lineHeight: "1.295",
            marginLeft: "36pt",
            marginTop: "0pt",
            marginBottom: "0pt",
          }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Swift Code – HBLILKLX
          </span>
        </p>
        <p>
          <br />
        </p>
        <p>
          <br />
        </p>
        <ul
          style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: "48px" }}
        >
          <li
            dir="ltr"
            style={{
              listStyleType: "disc",
              fontSize: "11pt",
              fontFamily: '"Noto Sans Symbols",sans-serif',
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre",
            }}
            aria-level={1}
          >
            <p
              dir="ltr"
              style={{
                lineHeight: "1.295",
                marginTop: "0pt",
                marginBottom: "8pt",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Calibri,sans-serif",
                  color: "#000000",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                In case of a bank deposit or an electronic transfer, proof of
                payment (eg: payment receipt) must be submitted along with the
                application through the applications submission portal.
              </span>
            </p>
          </li>
        </ul>
        <p>
          <br />
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Self-Evaluation Facility
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            All applicants are encouraged to do a self-evaluation using the
            self-evaluation facility provided on this site to determine whether
            they meet the minimum marks requirement for the Awards. Applications
            which do not meet the minimum marks requirement will not be
            considered for the Awards.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Panel of Judges
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The panel of judges of the National Educator Awards consists of
            fourteen (14) distinguished senior academics representing leading
            Sri Lankan Universities and the thirteen academic disciplines
            pertaining to the Awards.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Evaluation Process
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            To ensure fairness and transparency, evaluation of the applications
            will be done according to the evaluation criteria published on this
            site by the panel of 14 judges. &nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Each application will be evaluated by a sub-panel of three (3)
            judges. The sub-panel will consist of one judge from the relevant
            discipline and two judges outside the discipline.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The decision of the panel of judges shall be final and
            conclusive.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Selection of Awardees &nbsp;&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The applicant who receives the highest total score in each
            discipline will be awarded the gold award for that discipline.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The silver and bronze awards will be awarded to the applicants who
            receive the second and third highest total scores respectively in
            the relevant discipline.&nbsp;
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Winners’ Circle
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            At the conclusion of the evaluation process, the organizing
            committee will announce the winners’ circle consisting of the three
            award winners for each discipline on or before the{" "}
            <b>20th of November 2025.</b>
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 700,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            Announcement of Gold, Silver and Bronze Awardees
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            The Gold, Silver and Bronze Awardee for each discipline will be
            announced at the National Educator Awards ceremony held on the{" "}
            <b>26</b>
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <span style={{ fontSize: "0.6em", verticalAlign: "super" }}>
              <b>th</b>
            </span>
          </span>
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            <b>&nbsp;of November 2025 at Monarch Imperial.</b>
          </span>
        </p>
        <p
          dir="ltr"
          style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
        >
          <span
            style={{
              fontSize: "11pt",
              fontFamily: "Calibri,sans-serif",
              color: "#000000",
              backgroundColor: "transparent",
              fontWeight: 400,
              fontStyle: "normal",
              fontVariant: "normal",
              textDecoration: "none",
              verticalAlign: "baseline",
              whiteSpace: "pre-wrap",
            }}
          >
            &nbsp;
          </span>
        </p>
      </div>
    );
  } else {
    return (
      <div style={{ color: "black" }}>
        <p>
          I have not been found guilty of any disciplinary misconduct. There are
          no ongoing disciplinary actions or investigations against me. There
          are no ongoing audit inquiries against me, and I have not been subject
          to any recent audit inquiries with unresolved findings. All the
          information provided in this application is true and accurate to the
          best of my knowledge. The achievements and initiatives described are
          solely my work or a collaborative effort to which I have made a
          significant contribution. If collaborative work, I agree to provide
          any further details requested by the Evaluation Panel of NEA,
          including names of team members.The extended application, list of
          supporting documents required and the Schedule of interviews will be
          shared with you in due course via email.
        </p>
        <br />
        <p>
          පහත අත්සන් කරන මා කිසිම විනය විරෝධි ක්&zwj;රියාවකට වරද කරුවකු වී
          නොමැති බවත්, මා හට විරුද්ධව දැනට ක්&zwj;රියාත්මක වන විනය
          ක්&zwj;රියාමාර්ග හෝ විනය පරීක්ෂණ හෝ විගණන විමර්ශන හෝ නොවිසඳුනු විගණන
          විමසුම් කිසිවක් මා දැනුවත් ව නොමැති බවත් ප්&zwj;රකාශ කරමි. මා ලබා ඇති
          ජයග්&zwj;රහනය මාගේ ම උත්සාහයකින් ලබාගත් බවත්, ඒ සඳහා වූ සියලු විස්තර
          සංවිධායක මණ්ඩලය විසින් ඉල්ලුම් කරන ඕනෑම අවස්ථාවක ඉදිරිපත් කිරීමට
          සූදානම් බවත් මෙයින් ප්&zwj;රකාශ කර සිටිමි.
        </p>
        <br />
        <p>
          கீழே கையொப்பமிட்டுள்ள நான் எதுவிதமான குற்றசெயல்களுக்கும் ஒழுக்காற்று
          நடவடிக்கைகளுக்கும் உள்ளாக்கப்படவில்லை
        </p>
        <p>
          என்பதையும் இப்போது எனக்கு எதிராக நடைபெறுகின்ற ஒழுக்காற்று விசாரணைகளில்
          அல்லது தீர்க்கப்படாத பிரச்சனைகள் எதை பற்றியும் நான் அறிந்திருக்கவில்லை
          என்பதையும் தெரிவிக்கிறேன் மேலும் நான் பெற்றுக்கொண்ட வெற்றிகள்
          அனைத்தும் எனது முழு முயற்சியினூடாக கிடைக்கப்பெற்றவை எனவும் இது
          தொடர்பான வினாக்களை NEA மதிப்பீட்டுக் குழுவால் வினவப்படும் எந்த
          சந்தர்ப்பத்திலும் பதில் கூற கடமைப்பட்டுள்ளேன் எனவும் உறுதியளிக்கிறேன்.
        </p>
      </div>
    );
  }
};
