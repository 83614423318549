import React from "react";
import { cn } from "../../lib/utils";

const buttonVariants = {
  default:
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium bg-primary text-primary-foreground hover:bg-primary/90",
  outline:
    "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
  secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
};

function Button({ className, variant = "default", children, ...props }) {
  return (
    <button
      className={cn(
        buttonVariants[variant],
        "px-4 py-2 transition-colors ",
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
}

export { Button };
