import React, { useEffect, useState } from "react";
import { Button } from "../components/ui/Button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../components/ui/Card";
import WinnerCard from "../components/common/winnerCard";
import { awards2024General } from "../lib/awardsWinners";
import { useNavigate } from "react-router-dom";
import {
  ArrowRight,
  FileText,
  Filter,
  Users,
  Trophy,
  Mail,
  ArrowDown,
  Lightbulb,
  Target,
} from "lucide-react";
import { motion } from "framer-motion";

export const GeneralEducation = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const navigate = useNavigate();
  const steps = [
    {
      title: "Call for Applications",
      description: "Open submissions for award applications.",
      icon: FileText,
    },
    {
      title: "Filtering Applications",
      description: "Shortlist based on eligibility criteria.",
      icon: Filter,
    },
    {
      title: "Provincial Judging",
      description: "Evaluation by a panel of three judges.",
      icon: Users,
    },
    {
      title: "Finalizing Winners",
      description: "Selection of award recipients.",
      icon: Trophy,
    },
    {
      title: "Informing Winners",
      description: "Notifying successful applicants.",
      icon: Mail,
    },
  ];
  const awards = [
    {
      title: "Impactful Teaching",
      icon: Trophy,
      points: [
        "Demonstrated mastery of subject matter and pedagogical skills.",
        "Innovative and engaging teaching methods that cater to diverse learners.",
        "Fostering a growth mindset and a love for learning in students.",
        "Significant student learning outcomes, measured through quantitative and qualitative data.",
        "Creating a safe and inclusive classroom environment that promotes collaboration.",
      ],
    },
    {
      title: "Community Engagement",
      icon: Lightbulb,

      points: [
        "Collaborative relationships with colleagues, parents, and other stakeholders.",
        "Implementing initiatives that connect the classroom to the community and address local needs.",
        "Promoting student leadership and social responsibility within the community.",
        "Inspiring others through mentorship, professional development activities, or public outreach.",
        "Demonstrating cultural sensitivity and inclusivity in interactions with diverse communities.",
      ],
    },
    {
      title: "Educational Innovation",
      icon: Target,
      points: [
        "Pioneering new teaching approaches or using technology creatively to enhance learning.",
        "Addressing contemporary challenges in education and contributing to educational discourse.",
        "Collaborating with others to develop and share innovative educational practices.",
        "Demonstrating adaptability and resilience in response to changing educational landscapes.",
        "Inspiring colleagues and advocating for innovative approaches within the educational system.",
      ],
    },
  ];

  return (
    <>
      <section className="relative">
        {/* Background Image with Overlay */}
        <div className="absolute inset-0">
          <img
            src="/assets/img/general-award.webp"
            alt="Background"
            className="absolute inset-0 w-full h-full brightness-50 object-cover object-[50%_16%]"
          />
          <div className="absolute inset-0 bg-black/50" />
          {/* Overlay for better readability */}
        </div>

        {/* Content */}
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <div className="max-w-4xl mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                transition={{ duration: 0.8, delay: 0.2 }}
              >
                <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 tracking-tight">
                  <span className="text-orange-500">General</span> Education
                  Awards
                </h1>
              </motion.div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 20 }}
                transition={{ duration: 0.8, delay: 0.5 }}
              >
                <p className="text-md lg:text-lg md:text-xl text-gray-100 max-w-3xl mx-auto leading-relaxed">
                  The General Education category in National Educator Awards,
                  recognizes the pivotal role of schoolteachers in the education
                  of Sri Lanka. As such, three outstanding teachers from each of
                  the nine provinces will be recognized under three distinct
                  award categories: Impactful Teaching, Community Engagement,
                  and Educational Innovation.
                </p>
              </motion.div>
            </div>
          </div>

          <div className="max-w-7xl mx-auto grid gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {awards.map((award, index) => (
              <Card
                key={index}
                className="bg-gray-900  hover:bg-gray-950 border-2 border-orange-700 transition-all duration-300 hover:shadow-lg hover:translate-y-[-4px] flex flex-col h-full"
              >
                <CardHeader className="text-white p-6 flex flex-col items-center bg-slate-800 rounded-sm">
                  {React.createElement(award.icon, {
                    className: "w-10 h-10 mb-3 text-orange-500",
                    strokeWidth: 1.5,
                  })}
                  <CardTitle className="text-white font-bold text-center">
                    {award.title}
                  </CardTitle>
                </CardHeader>
                <CardContent className="p-6">
                  <ul className="space-y-4 mb-6">
                    {award.points.map((point, idx) => (
                      <li key={idx} className="flex items-start">
                        <span className="inline-block w-2 h-2 mt-2 mr-2 rounded-full bg-orange-500 flex-shrink-0"></span>
                        <span className="text-sm text-gray-300">{point}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter className="h-[100px] flex items-center justify-center p-6">
                  <Button
                    className="w-full bg-orange-600 hover:bg-slate-900 hover:text-orange-400 hover:border-2 hover:border-orange-400 h-12 text-lg"
                    onClick={() =>
                      navigate(
                        `/apply?category=${encodeURIComponent(
                          "general-education"
                        )}&award=${encodeURIComponent(award.title)}`
                      )
                    }
                  >
                    Proceed to Application
                  </Button>
                </CardFooter>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-slate-900 py-5 px-4 sm:px-6 lg:px-8">
        <div className="max-w-full  mx-auto">
          <div className="text-center mb-16">
            <div className="w-full h-full py-5 lg:py-6 relative">
              <h1
                className="text-3xl font-bold text-[#dd7538] sm:text-5xl capitalize tracking-wider px-4 lg:px-0 section-title relative mx-auto w-fit z-20"
                style={{ fontFamily: "Poppins" }}
                data-aos="fade-down"
                data-aos-delay="100"
              >
                PAST WINNERS
              </h1>
              <div className="flex max-w-screen-2xl mt-10 flex-col md:flex-row mx-auto">
                <div className="flex flex-col w-full py-4 px-8 gap-8 items-center justify-center">
                  <div className="gold ">
                    {/* <h1 className="font-semibold text-left text-2xl md:text-[28px] text-[#e99e00]">
                      Western Province Winners{" "}
                    </h1> */}
                    <div className="flex justify-center lg:justify-start items-stretch flex-row flex-wrap w-full gap-8 mt-8">
                      {awards2024General
                        .filter(
                          (data) =>
                            data.province === "Western Province" ||
                            data.province === "Central Province" ||
                            data.province === "Southern Province" ||
                            data.province === "North-Western Province"
                        )
                        .slice(0, 3)
                        .map((data, index) => {
                          if (index === 3) {
                            return (
                              <WinnerCard
                                data={data}
                                index={index}
                                showMore={true} // Add a prop to indicate this card should show the button
                                key={index}
                                selectedYear={"2024"}
                                selectedCategory={"general"}
                                selectedProvince={"Western Province"}
                              />
                            );
                          } else {
                            return (
                              <WinnerCard
                                data={data}
                                index={index}
                                selectedYear={2024}
                                selectedCategory={"general"}
                                selectedProvince={"Western Province"}
                              />
                            );
                          }
                        })}
                      <div className="  px-4 mb-8 flex items-center justify-center">
                        <Button
                          data-aos="fade-up"
                          data-aos-delay={100 + 50 * 4}
                          variant="outline"
                          size="default"
                          className="border-2 border-transparent bg-orange-600 text-white rounded-sm hover:bg-slate-800 hover:text-orange-400 hover:border-2 hover:border-orange-400 transition-colors duration-300 text-base whitespace-nowrap font-semibold"
                          onClick={() => navigate("/winners")}
                        >
                          Show More
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-slate-900 text-white py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h1
            className="text-3xl font-bold text-[#dd7538] sm:text-5xl capitalize tracking-wider px-4 lg:px-0 section-title relative mx-auto w-fit z-20 mb-20"
            style={{ fontFamily: "Poppins" }}
            data-aos="fade-down"
            data-aos-delay="100"
          >
            How We Process
          </h1>
          <div
            className="grid grid-cols-1 md:grid-cols-5 gap-8 px-2"
            data-aos="fade-down"
            data-aos-delay="100"
          >
            {steps.map((step, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="relative"
              >
                <div className="bg-gray-800 rounded-lg p-6 h-full flex flex-col items-center text-center transition-all duration-300 hover:shadow-lg hover:shadow-orange-500/20 group">
                  <div className="w-12 h-12 bg-orange-500 rounded-full flex items-center justify-center mb-4 transition-transform duration-300 group-hover:scale-110">
                    <step.icon className="w-6 h-6 text-black" />
                  </div>
                  <div className="absolute -top-3 -left-3 w-8 h-8 bg-orange-500 rounded-full flex items-center justify-center text-black font-bold">
                    {index + 1}
                  </div>
                  <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
                  <p className="text-gray-400">{step.description}</p>
                </div>
                {index < steps.length - 1 && (
                  <div className="hidden md:block absolute top-1/2 -right-4 transform -translate-y-1/2">
                    <ArrowRight className="w-8 h-8 text-orange-500" />
                  </div>
                )}
                {index < steps.length - 1 && (
                  <div className="md:hidden absolute -bottom-4 left-1/2 transform -translate-x-1/2">
                    <ArrowDown className="w-8 h-8 text-orange-500" />
                  </div>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};
