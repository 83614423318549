import { useState, useEffect, useRef } from "react";
import { ChevronDown, Menu, X } from "lucide-react";
import { Button } from "../components/ui/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../components/ui/Collapsible";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetTitle,
} from "../components/ui/Sheet";
import { cn } from "../lib/utils";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Navigation() {
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [activeDropdownMobile, setActiveDropdownMobile] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [open, setOpen] = useState(false);

  const generalRef = useRef(null);
  const higherRef = useRef(null);
  const aboutRef = useRef(null);

  const toggleDropdown = (name) => {
    setActiveDropdown(activeDropdown === name ? null : name);
  };
  const toggleDropdownMobile = (dropdown) => {
    setActiveDropdownMobile(
      activeDropdownMobile === dropdown ? null : dropdown
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        generalRef.current &&
        !generalRef.current.contains(event.target) &&
        higherRef.current &&
        !higherRef.current.contains(event.target) &&
        aboutRef.current &&
        !aboutRef.current.contains(event.target)
      ) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header
        className={cn(
          "fixed top-0 left-0 right-0 z-50 transition-all duration-300 ",
          isScrolled
            ? "bg-[#110909] text-white shadow-lg py-2"
            : "bg-[#110909]/90 text-white py-4 h-20"
        )}
      >
        <div className="container mx-auto lg:px-28 px-4">
          <div className="flex items-center justify-between">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5 flex flex-row">
                <img
                  className="h-10 w-auto"
                  src="/assets/img/award.webp"
                  alt=""
                />
                <span className="text-sm ml-4 text-white">
                  NATIONAL EDUCATOR
                  <br />
                  AWARDS 2025
                </span>
              </Link>
            </div>

            <nav className="hidden lg:flex items-center space-x-12">
              <div className="relative group" ref={generalRef}>
                <button
                  className="flex items-center space-x-1 py-2 text-white text-lg font-semibold hover:text-orange-400 transition-colors"
                  onClick={() => toggleDropdown("general")}
                >
                  <span>General Education</span>
                  <ChevronDown
                    className={cn(
                      "h-4 w-4 transition-transform duration-200",
                      activeDropdown === "general" ? "rotate-180" : ""
                    )}
                  />
                </button>
                <div
                  className={cn(
                    "absolute left-0 mt-2 w-64 bg-slate-800 border-[1px] text-white rounded-md shadow-lg overflow-hidden transition-all duration-300 origin-top-left",
                    activeDropdown === "general"
                      ? "opacity-100 scale-100 translate-y-0"
                      : "opacity-0 scale-95 -translate-y-2 pointer-events-none"
                  )}
                >
                  <div className="p-4">
                    <h3 className="font-semibold text-lg mb-2">
                      General Education Awards
                    </h3>
                    <p className="text-sm text-white mb-4">
                      Applications are open for general education awards.
                    </p>
                    <Link to="/general-edu">
                      <Button
                        className="w-full bg-orange-500 hover:bg-orange-600 text-white"
                        onClick={() => toggleDropdown(null)}
                      >
                        Apply Now
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="relative group" ref={higherRef}>
                <button
                  className="flex items-center space-x-1 py-2 text-white text-lg font-semibold hover:text-orange-400 transition-colors"
                  onClick={() => toggleDropdown("higher")}
                >
                  <span>Higher Education</span>
                  <ChevronDown
                    className={cn(
                      "h-4 w-4 transition-transform duration-200",
                      activeDropdown === "higher" ? "rotate-180" : ""
                    )}
                  />
                </button>
                <div
                  className={cn(
                    "absolute left-0 mt-2 w-64 bg-slate-800 border-[1px] text-white rounded-md shadow-lg overflow-hidden transition-all duration-300 origin-top-left",
                    activeDropdown === "higher"
                      ? "opacity-100 scale-100 translate-y-0"
                      : "opacity-0 scale-95 -translate-y-2 pointer-events-none"
                  )}
                >
                  <div className="p-4">
                    <h3 className="font-semibold text-lg mb-2">
                      Higher Education Awards
                    </h3>
                    <p className="text-sm text-white mb-4">
                      Applications are open for higher education awards.
                    </p>
                    <Link to="/higher-edu">
                      <Button
                        className="w-full bg-orange-500 hover:bg-orange-600 text-white"
                        onClick={() => toggleDropdown(null)}
                      >
                        Apply Now
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="relative group" ref={aboutRef}>
                <button
                  className="flex items-center space-x-1 py-2 text-white text-lg font-semibold hover:text-orange-400 transition-colors"
                  onClick={() => toggleDropdown("about")}
                >
                  <span>About Us</span>
                  <ChevronDown
                    className={cn(
                      "h-4 w-4 transition-transform duration-200",
                      activeDropdown === "about" ? "rotate-180" : ""
                    )}
                  />
                </button>
                <div
                  className={cn(
                    "absolute left-0 mt-2 w-52 bg-slate-800 text-gray-100 rounded-md shadow-lg overflow-hidden transition-all duration-300 origin-top-left",
                    activeDropdown === "about"
                      ? "opacity-100 scale-100 translate-y-0"
                      : "opacity-0 scale-95 -translate-y-2 pointer-events-none"
                  )}
                >
                  <div className="p-4 space-y-2">
                    <HashLink
                      to="/#aboutus"
                      className="py-2 px-3 hover:bg-white/10 rounded-md transition-colors flex items-center justify-between"
                      onClick={() => toggleDropdown("about")}
                    >
                      <span>About Us</span>
                    </HashLink>
                    <HashLink
                      to="/#steering"
                      className="py-2 px-3 hover:bg-white/10 rounded-md transition-colors flex items-center justify-between"
                      onClick={() => toggleDropdown("about")}
                    >
                      <span>Steering Committee</span>
                    </HashLink>
                    <HashLink
                      to="/#team"
                      className="block py-2 px-3 hover:bg-white/10 rounded-md transition-colors  items-center justify-between"
                      onClick={() => toggleDropdown("about")}
                    >
                      <span>Our Team</span>
                    </HashLink>
                    {/* <HashLink
                      to="/#judges"
                      className="block py-2 px-3 hover:bg-white/10 rounded-md transition-colors  items-center justify-between"
                    >
                      <span>Judges</span>
                    </HashLink> */}
                  </div>
                </div>
              </div>
              <Link
                to="/winners"
                className="py-2 text-white text-lg font-semibold hover:text-orange-400 transition-colors"
              >
                Past Winners
              </Link>
            </nav>
            <div className="block lg:hidden">
              <Sheet open={open} onOpenChange={setOpen}>
                <SheetTrigger asChild>
                  <Button variant="ghost" size="icon" className="h-10 w-10">
                    <Menu className="h-6 w-6" />
                    <span className="sr-only">Toggle menu</span>
                  </Button>
                </SheetTrigger>
                <SheetContent side="right" className="w-[300px] p-0">
                  <SheetTitle></SheetTitle>
                  <div className="flex flex-col h-full bg-slate-950 text-white">
                    {/* Header */}
                    <div className="flex items-center justify-between p-4 border-b border-orange-500/20">
                      <div className="flex items-center space-x-2">
                        <a href="/" className="-m-1.5 p-1.5 flex flex-row">
                          <span className="sr-only">Your Company</span>
                          <img
                            className="h-10 w-auto"
                            src="/assets/img/award.webp"
                            alt=""
                          />
                          <span className="text-sm ml-4 text-white">
                            NATIONAL EDUCATOR
                            <br />
                            AWARDS 2025
                          </span>
                        </a>
                      </div>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => setOpen(false)}
                        className="text-white hover:text-orange-500"
                      >
                        <X className="h-6 w-6" />
                        <span className="sr-only">Close menu</span>
                      </Button>
                    </div>

                    {/* Navigation */}
                    <div className="flex-1 overflow-auto py-4 px-2">
                      <nav className="space-y-4 ">
                        {/* General Education */}
                        <div className="w-full">
                          <Collapsible
                            open={activeDropdownMobile === "generalEducation"}
                            onOpenChange={() =>
                              toggleDropdownMobile("generalEducation")
                            }
                            className="w-full"
                          >
                            <CollapsibleTrigger asChild>
                              <Button
                                variant="ghost"
                                className="w-full flex items-center gap-3 justify-between text-gray-200 text-lg font-semibold hover:text-orange-500 hover:bg-white/5 transition-all duration-200 "
                              >
                                <span>General Education</span>
                                <ChevronDown
                                  className={`h-4 w-4 transition-transform duration-200 ${
                                    activeDropdown === "generalEducation"
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                />
                              </Button>
                            </CollapsibleTrigger>
                            <CollapsibleContent className="pt-2">
                              <div className="p-4 bg-white/5 rounded-md space-y-4">
                                <p className="text-sm text-gray-300 py-2">
                                  Application are open for general education
                                  awards.
                                </p>
                                <Link to="/general-edu">
                                  <Button
                                    className="w-full bg-orange-500 hover:bg-orange-600 text-white"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    Apply Now
                                  </Button>
                                </Link>
                              </div>
                            </CollapsibleContent>
                          </Collapsible>
                        </div>

                        {/* Higher Education */}
                        <div className="w-full">
                          <Collapsible
                            open={activeDropdownMobile === "higherEducation"}
                            onOpenChange={() =>
                              toggleDropdownMobile("higherEducation")
                            }
                            className="w-full"
                          >
                            <CollapsibleTrigger asChild>
                              <Button
                                variant="ghost"
                                className="w-full flex items-center gap-3 justify-between  text-white text-lg font-semibold hover:text-orange-500 hover:bg-white/5 transition-all duration-200"
                              >
                                <span>Higher Education</span>
                                <ChevronDown
                                  className={`h-4 w-4 transition-transform duration-200 ${
                                    activeDropdown === "higherEducation"
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                />
                              </Button>
                            </CollapsibleTrigger>
                            <CollapsibleContent className="pt-2">
                              <div className="p-4 bg-white/5 rounded-md space-y-4">
                                <p className="text-sm text-gray-300 py-2">
                                  Applications are open for higher education
                                  awards.
                                </p>
                                <Link to="/higher-edu">
                                  <Button
                                    className="w-full bg-orange-500 hover:bg-orange-600 text-white"
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                  >
                                    Apply Now
                                  </Button>
                                </Link>
                              </div>
                            </CollapsibleContent>
                          </Collapsible>
                        </div>

                        {/* About Us */}
                        <div className="w-full">
                          <Collapsible
                            open={activeDropdownMobile === "aboutUs"}
                            onOpenChange={() => toggleDropdownMobile("aboutUs")}
                            className="w-full"
                          >
                            <CollapsibleTrigger asChild>
                              <Button
                                variant="ghost"
                                className="w-full flex items-center gap-3 justify-between text-white text-lg font-semibold hover:text-orange-500 hover:bg-white/5 transition-all duration-200"
                              >
                                <span>About Us</span>
                                <ChevronDown
                                  className={`h-4 w-4 transition-transform duration-200 ${
                                    activeDropdown === "aboutUs"
                                      ? "rotate-180"
                                      : ""
                                  }`}
                                />
                              </Button>
                            </CollapsibleTrigger>
                            <CollapsibleContent className="pt-2">
                              <div className="p-4 bg-white/5 rounded-md space-y-3">
                                <HashLink
                                  to="/#aboutus"
                                  className="block py-2 px-3 rounded hover:bg-white/10 text-gray-200 hover:text-orange-500 transition-colors"
                                  onClick={() => setOpen(false)}
                                >
                                  About Us
                                </HashLink>
                                <HashLink
                                  to="/#steering"
                                  className="block py-2 px-3 rounded hover:bg-white/10 text-gray-200 hover:text-orange-500 transition-colors"
                                  onClick={() => setOpen(false)}
                                >
                                  Steering Committee
                                </HashLink>
                                <HashLink
                                  to="/#team"
                                  className="block py-2 px-3 rounded hover:bg-white/10 text-gray-200 hover:text-orange-500 transition-colors"
                                  onClick={() => setOpen(false)}
                                >
                                  Our Team
                                </HashLink>
                                {/* <HashLink
                                  to="/#judges"
                                  className="block py-2 px-3 rounded hover:bg-white/10 text-gray-200 hover:text-orange-500 transition-colors"
                                  onClick={() => setOpen(false)}
                                >
                                  Judges
                                </HashLink> */}
                              </div>
                            </CollapsibleContent>
                          </Collapsible>
                        </div>

                        {/* Past Winners */}
                        <Button
                          variant="ghost"
                          asChild
                          className="w-full text-left text-white text-lg font-semibold hover:text-orange-500 hover:bg-white/5 transition-all duration-200"
                        >
                          <Link to="/winners" onClick={() => setOpen(false)}>
                            Past Winners
                          </Link>
                        </Button>
                      </nav>
                    </div>
                  </div>
                </SheetContent>
              </Sheet>
            </div>
          </div>
        </div>
      </header>
      <div className="h-7"></div>
    </>
  );
}
