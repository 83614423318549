import { motion } from "framer-motion";

const WelcomeSection = () => {
  const bulletPoints = [
    "The NEA celebrates your contribution as an outstanding educator, giving you the recognition you truly deserve at a prestigious national platform.",
    "This is your chance to be appreciated for your passion, hard work, and the lasting impact you have made in your academic discipline.",
    "It enhances your career by boosting your professional credibility and opening doors to new opportunities in education, leadership, and beyond.",
    "NEA offers you a great opportunity to connect with top educators, policymakers, and industry leaders, expanding your professional network and influence in the education sector.",
    "It will serve as a beacon of inspiration for fellow educators and students, shaping the future of education in Sri Lanka.",
  ];

  return (
    <section className="py-16 px-4 md:px-8 lg:px-16 text-white">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-20 ">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
            viewport={{ once: true, amount: 0.2 }}
            className="space-y-6"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-6 text-orange-500 text-center md:text-left">
              Welcome
            </h2>
            <p className="text-md lg:text-lg font-normal leading-relaxed px-2 md:px-0 text-justify">
              The National Educator Awards (NEA) celebrate excellence in
              education in Sri Lanka, recognizing the contributions of educators
              from both higher education and general education sectors. NEA
              honour teachers' dedication to improving the quality of education,
              with higher education academics evaluated on teaching, scholarly
              work, and national & international contributions, while
              schoolteachers are assessed on impactful teaching, community
              engagement, and teaching innovation. Launched in 2023, the NEA was
              launched by recognizing higher education professionals in 13
              disciplines. In 2024, it expanded to include a school category,
              honouring three outstanding teachers from each province under the
              three award categories.
            </p>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 2, delay: 0.5 }}
            viewport={{ once: true, amount: 0.2 }}
            className="space-y-6"
          >
            <h3 className="text-4xl md:text-5xl font-bold mb-6 text-orange-500 text-center md:text-left">
              Why Should You Apply?
            </h3>
            <ul className="space-y-4">
              {bulletPoints.map((point, index) => (
                <motion.li
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
                  viewport={{ once: true, amount: 0.2 }}
                  className="flex items-start text-md lg:text-lg px-1 md:px-0"
                >
                  <span className="text-orange-500 mr-2">•</span>
                  <span>{point}</span>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default WelcomeSection;
