import React, { useEffect, useState } from "react";
import WinnerCard from "../components/common/winnerCard";
import {
  awards2023,
  awards2024General,
  awards2024Higher,
} from "../lib/awardsWinners";

export const Winners = () => {
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedCategory, setSelectedCategory] = useState("higher");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const getAwards = () => {
    if (selectedYear === 2023) return awards2023;
    return selectedCategory === "general"
      ? awards2024General
      : awards2024Higher;
  };

  return (
    <div className="w-full h-full min-h-screen py-10 lg:py-6 relative">
      <h1
        className="text-3xl font-bold text-[#dd7538] sm:text-5xl capitalize tracking-wider px-4 lg:px-0 section-title relative mx-auto w-fit z-20"
        style={{ fontFamily: "Poppins" }}
        data-aos="fade-down"
        data-aos-delay="100"
      >
        PAST WINNERS
      </h1>
      <div className="flex max-w-screen-2xl mt-10 flex-col md:flex-row mx-auto">
        <div className="min-w-[140px] flex flex-col border-b-2 md:border-b-0 md:border-r-2 border-gray-600 px-4 py-4 md:py-2 h-full md:h-[720px]">
          <h3 className="font-semibold text-lg md:text-xl text-white mb-4">
            Year
          </h3>
          <div className="flex w-full flex-row md:flex-col gap-3">
            {[2024, 2023].map((year) => (
              <button
                key={year}
                className={`rounded-md border-2 w-full max-w-28 px-2 py-1 text-base md:text-lg font-medium ${
                  selectedYear === year
                    ? "bg-orange-600 text-white border-orange-400"
                    : "border-orange-400 text-orange-400"
                }`}
                onClick={() => {
                  setSelectedYear(year);
                  setSelectedCategory("higher");
                }}
              >
                {year}
              </button>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-full py-4 px-4 md:px-8 gap-8">
          {selectedYear === 2024 && (
            <div className="flex flex-col md:flex-row gap-4 items-start">
              <div className="flex gap-3 w-full md:w-96">
                {["higher", "general"].map((category) => (
                  <button
                    key={category}
                    className={`rounded-md border-2 w-48 px-2 md:px-4 py-2 text-base md:text-lg font-medium ${
                      selectedCategory === category
                        ? "bg-orange-600 text-white border-orange-400"
                        : "border-orange-400 text-orange-400"
                    }`}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category.charAt(0).toUpperCase() + category.slice(1)}{" "}
                    Education
                  </button>
                ))}
              </div>
            </div>
          )}
          {selectedCategory === "higher" || selectedYear === 2023
            ? ["gold", "silver", "bronze"].map((awardType) => (
                <div key={awardType} className={awardType}>
                  <h1
                    className={`font-semibold text-2xl md:text-[28px] ${
                      awardType === "gold"
                        ? "text-[#e99e00]"
                        : awardType === "silver"
                        ? "text-gray-400"
                        : "text-orange-800"
                    }`}
                  >
                    {awardType.charAt(0).toUpperCase() + awardType.slice(1)}{" "}
                    Winners
                  </h1>
                  <div className="flex justify-center lg:justify-start items-stretch flex-row flex-wrap w-full gap-8 mt-8">
                    {getAwards()
                      .filter((data) => data.award === awardType)
                      .map((data, index) => (
                        <WinnerCard
                          key={index}
                          data={data}
                          index={index}
                          selectedYear={selectedYear}
                          selectedCategory={selectedCategory}
                        />
                      ))}
                  </div>
                </div>
              ))
            : [
                "Western Province",
                "Central Province",
                "Southern Province",
                "North-West Province",
                "Sabaragamuwa Province",
                "Northern Province",
                "Eastern Province",
                "Uva Province",
                "North-Central Province",
              ].map((province) => (
                <div
                  key={province}
                  className={province.replace(" ", "-").toLowerCase()}
                >
                  <h1 className="font-semibold text-2xl md:text-[28px] text-[#e99e00]">
                    {province} Winners
                  </h1>
                  <div className="flex justify-center lg:justify-start items-stretch flex-row flex-wrap w-full gap-8 mt-8">
                    {getAwards()
                      .filter((data) => data.province === province)
                      .map((data, index) => (
                        <WinnerCard
                          key={index}
                          data={data}
                          index={index}
                          selectedYear={selectedYear}
                          selectedCategory={selectedCategory}
                          selectedProvince={data.province}
                        />
                      ))}
                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};
