export const awards2023 = [
  {
    category: "Engineering",
    award: "gold",
    name: "Prof. K B S N Jinadasa",
    image: "11. Prof. K B S N Jinadasa.jpg",
    description:
      "Professor in Civil Engineering, Department of Civil Engineering, University of Peradeniya",
  },
  {
    category: "Applied Sciences",
    award: "gold",
    name: "Prof. Jayanetti Koralalage Don Sumedha Jayanetti",
    image: "1. Prof. Jayanetti Koralalage Don Sumedha Jayanetti.jpg",
    description:
      "Retired Senior Professor in Physics, Department of Instrumentation and Automation Technology, Faculty of Technology, University of Colombo. Former Dean, Faculty of Technology, University of Colombo.",
  },
  {
    category: "Applied Sciences",
    award: "silver",
    name: "Prof. Samitha Deraniyagala",
    image: "2. Prof. Samitha Deraniyagala.jpg",
    description:
      "Emeritus Professor, Department of Chemistry, University of Sri Jayewardenepura",
  },
  {
    category: "Architecture",
    award: "gold",
    name: "Prof. Muthuwahandi Lalith de Silva",
    image: "3. Prof. Muthuwahandi Lalith de Silva.jpg",
    description:
      "Head, School of Architecture, Sri Lanka Institute of Information Technology",
  },
  {
    category: "Business and Management",
    award: "gold",
    name: "Prof. Y.K.Weerakoon Banda",
    image: "4. Prof. Y.K.Weerakoon Banda.jpg",
    description:
      "Professor, Department of Business Management, Business School, Sri Lanka Institute of Information Technology",
  },
  {
    category: "Business and Management",
    award: "silver",
    name: "Prof. Ruwan Jayathilaka",
    image: "5. Prof. Ruwan Jayathilaka.jpg",
    description:
      "Professor/Head, Department of Information Management, Business School, Sri Lanka Institute of Information Technology",
  },
  {
    category: "Business and Management",
    award: "bronze",
    name: "Prof. Potupitiya Gamaathige Sanjeevani Amila Jayarathne",
    image: "6. Prof. Potupitiya Gamaathige Sanjeevani Amila Jayarathne.jpg",
    description:
      "Professor, Department of Business Management, Business School, Sri Lanka Institute of Information Technology",
  },
  {
    category: "Nursing and Health Sciences",
    award: "gold",
    name: "Prof. Meegoda Kankanange Dona Lalitha Meegoda",
    image: "12. Prof. Meegoda Kankanange Dona Lalitha Meegoda.jpg",
    description:
      "Professor, Department of Nursing and Midwifery, Faculty of Allied Health Sciences, University of Sri Jayewardenepura",
  },
  {
    category: "Nursing and Health Sciences",
    award: "silver",
    name: "Prof. Thamara Amarasekara",
    image: "13. Prof. Thamara Amarasekara.jpg",
    description:
      "Professor, Department of Nursing and Midwifery, Faculty of Allied Health Sciences, University of Sri Jayewardenepura",
  },
  {
    category: "Nursing and Health Sciences",
    award: "bronze",
    name: "Prof. Sudath Warnakulasuriya",
    image: "14. Prof. Sudath Warnakulasuriya.jpg",
    description: "Dean, Faculty of Nursing, University of Colombo",
  },
  {
    category: "Education",
    award: "gold",
    name: "Prof. Shironica Karunanayaka",
    image: "9. Prof. Shironica Karunanayaka.jpg",
    description:
      "Senior Professor in Educational Technology, Department of Secondary & Tertiary Education, Faculty of Education, The Open University of Sri Lanka (OUSL)",
  },
  {
    category: "Education",
    award: "silver",
    name: "Prof. Lakshman Wedikkarage",
    image: "10. Prof. Lakshman Wedikkarage.jpg",
    description:
      "Professor, Department of Social Science Education, Faculty of Education, University of Colombo",
  },
  {
    category: "Language and Literature",
    award: "gold",
    name: "Dr. Harsha Dulari Wijesekera",
    image: "15. Dr. Harsha Wijesekera.jpg",
    description:
      "Visiting Fellow, School of Literature Languages and Linguistics, Australian National University. Senior Lecturer, Postgraduate Institute of English, The Open University of Sri Lanka (OUSL)",
  },
  {
    category: "Psychology & Counselling",
    award: "gold",
    name: "Dr. Chulani Jayasrimalee Herath",
    image: "18. Dr. Chulani Herath.jpg",
    description:
      "Head, Department of Psychology and Counselling, Faculty of Health Sciences, The Open University of Sri Lanka",
  },
  {
    category: "Computing",
    award: "gold",
    name: "Prof. Kamalanath Priyantha Hewagamage",
    image: "7. Prof. Kamalanath Priyantha Hewagamage.jpg",
    description:
      "Senior Professor in Computer Science, University of Colombo School of Computing",
  },
  {
    category: "Computing",
    award: "silver",
    name: "Prof. Sanath Jayasena",
    image: "8. Prof. Sanath Jayasena.jpg",
    description:
      "Associate Professor, Department of Computer Science & Engineering, Faculty of Engineering, University of Moratuwa",
  },
  {
    category: "Mathematics and Statistics",
    award: "gold",
    name: "Prof. Sarath Peiris",
    image: "17. Prof. Sarath Peiris.jpg",
    description:
      "Head, Department of Mathematics and Statistics, Faculty of Humanities and Sciences, Sri Lanka Institute of Information Technology",
  },
  {
    category: "Quantity Surveying",
    award: "silver",
    name: "Dr. Himal Suranga Jayasena",
    image: "19. Dr. Himal Suranga Jayasena.jpg",
    description: "Senior Lecturer, University of Moratuwa",
  },
  {
    category: "Law",
    award: "gold",
    name: "Prof. Naazima Kamardeen",
    image: "16. Prof. Naazima Kamardeen.jpg",
    description:
      "Professor, Department of Commercial Law, Faculty of Law, University of Colombo",
  },
];

export const awards2024General = [
  {
    category: "Community Engagement",
    province: "Western Province",
    name: "Mr. Upul Nawarathne",
    image: "Mr. Upul Nawarathne_WP.jpg",
    description: "S Thomas' Preparatory School - Church of Ceylon",
  },
  {
    category: "Educational Innovation",
    province: "Western Province",
    name: "Mr. Indrachapa Kuruppuarachchi",
    image: "Mr. Indrachapa Kuruppuarachchi_WP.jpg",
    description: "Asian International School",
  },
  {
    category: "Impactful Teaching",
    province: "Western Province",
    name: "Mr. Suresh Saputhanthri",
    image: "Mr. Suresh Saputhanthri_WP.jpg",
    description: "Sangamiththa Balika Maha Vidyalaya Aluthgama - Kalutara",
  },
  {
    category: "Community Engagement",
    province: "Central Province",
    name: "Mr. WGI Dharmawardhana",
    image: "Mr. WGI Dharmawardhana_CP.jpg",
    description: "K/Atabage Udagama M V",
  },
  {
    category: "Impactful Teaching",
    province: "Central Province",
    name: "Ms. NDCS Gunathilake",
    image: "NDCS Gunathilake_CP.jpg",
    description: "CP/K/Ranabima Royal College",
  },
  {
    category: "Community Engagement",
    province: "Eastern Province",
    name: "Mr. KHCL Wikramasinghe",
    image: "Mr. KHCL Wikramasinghe_EP.jpg",
    description: "T/Kn/Agbopura Maha Vidayalaya",
  },
  {
    category: "Educational Innovation",
    province: "Eastern Province",
    name: "Mr. MNM Ilham",
    image: "Mr. MNM Ilham_EP.jpg",
    description: "T/kin/Muslim Ladies College (N S)",
  },
  {
    category: "Impactful Teaching",
    province: "Eastern Province",
    name: "Mr. DMS Dasnayake",
    image: "Mr. DMS Dasnayake_EP.jpg",
    description: "T/kan/ DS Senayaka M V",
  },
  {
    category: "Community Engagement",
    province: "North-Central Province",
    name: "Mr. G.H.J. Prasanna",
    image: "Mr. G,H.J. Prasanna_NC.jpg",
    description: "A/Mahasen National College Rajanganaya",
  },
  {
    category: "Impactful Teaching",
    province: "North-Central Province",
    name: "Ms. MFF Fazmila",
    image: "Ms. MFF Fazmila_NC.jpg",
    description: "Kekirawa Muslim Maha Vidyalaya",
  },
  {
    category: "Community Engagement",
    province: "North-West Province",
    name: "Ms. RMWK Rupasinghe",
    image: "Ms. RMWK Rupasinghe_NW.jpg",
    description: "Herathgama primary school",
  },
  {
    category: "Impactful Teaching",
    province: "North-West Province",
    name: "Ms. SMAJ Siriwardhana",
    image: "Ms. SMAJ Siriwardhana_NW.jpg",
    description: "Chillaw Bishop Edmand Peiris Primary School",
  },
  {
    category: "Community Engagement",
    province: "Northern Province",
    name: "Mr. N. Kanndasan",
    image: "Mr. N. Kanndasan_NP.jpg",
    description: "J/Karampaikuruchchi G T M School Varan",
  },
  {
    category: "Educational Innovation",
    province: "Northern Province",
    name: "Ms. M. Uruthirathasan",
    image: "Ms. M. Uruthirathasan_NP.jpg",
    description: "Kn/lyakachchi Maha Vidyalayam",
  },
  {
    category: "Impactful Teaching",
    province: "Northern Province",
    name: "Mr. A. Gajathuranjan",
    image: "Mr. A. Gajathuranjan_NP.jpg",
    description: "J/Moolai Saivapragasa Vidyalayam",
  },
  {
    category: "Community Engagement",
    province: "Sabaragamuwa Province",
    name: "Ms. CT Colambage",
    image: "Ms. CT Colambage_SP.jpg",
    description: "Ke/St Mary's College Kegalle",
  },
  {
    category: "Educational Innovation",
    province: "Sabaragamuwa Province",
    name: "Mr. AM Rajah",
    image: "Mr. AM Rajah_SP.jpg",
    description: "KG/DEHI/Sulaimaniya College",
  },
  {
    category: "Impactful Teaching",
    province: "Sabaragamuwa Province",
    name: "Mr. SMGS Muhandiram",
    image: "Mr. SMGS Muhandiram_SP.jpg",
    description: "R/ Dharmaloka maha Vidhyalaya Pelmadulla",
  },
  {
    category: "Community Engagement",
    province: "Southern Province",
    name: "Mr. EGS Shantha",
    image: "Mr. EGS Shantha_SO.jpg",
    description: "MR/ Deniyaya Central College",
  },
  {
    category: "Educational Innovation",
    province: "Southern Province",
    name: "Mr. Piyal Ranjith",
    image: "Mr. Piyal Ranjith_SO.jpg",
    description: "H/Ambalantota M V",
  },
  {
    category: "Impactful Teaching",
    province: "Southern Province",
    name: "Ms. BAD Sandaruwani",
    image: "Ms. BAD Sandaruwani_SO.jpg",
    description: "M/R Diyalape K V",
  },
  {
    category: "Community Engagement",
    province: "Uva Province",
    name: "Mrs. WM Surangika",
    image: "Mrs. WM Surangika_UP.jpg",
    description: "B/Millaththawa Maha vidyalaya",
  },
  {
    category: "Educational Innovation",
    province: "Uva Province",
    name: "Mr. Saminda Ekanayake",
    image: "Mr. Saminda Ekanayake_UP.jpg",
    description: "B/Millaththawa vidyalaya",
  },
  {
    category: "Impactful Teaching",
    province: "Uva Province",
    name: "Mr. AGRP Bandara",
    image: "Mr. AGRP Bandara_UP.jpg",
    description: "B/Gambadda vidyalaya",
  },
];
export const awards2024Higher = [
  {
    category: "Nursing and Health Sciences",
    award: "gold",
    name: "Dr. Balasingam Balagobi",
    image: "Dr.Balasingam Balagobi_UoJ.jpg",
    description: "University of Jaffna",
  },
  {
    category: "Applied Sciences",
    award: "gold",
    name: "Prof. Babaranda De Silva",
    image: "Prof.Babaranda De Silva_UoJ.jpg",
    description: "University of Sri Jayewardenepura",
  },
  {
    category: "Business & Management",
    award: "gold",
    name: "Prof. Ruwan Jayathilaka",
    image: "Prof.Ruwan Jayathilaka_SLIIT.jpg",
    description: "Sri Lanka Institute of Information Technology",
  },
  {
    category: "Computing",
    award: "gold",
    name: "Prof. Roshan Ragel",
    image: "Prof.Roshan Ragel_UoP.jpg",
    description: "University of Peradeniya",
  },
  {
    category: "Engineering",
    award: "gold",
    name: "Prof. Ranjith Dissanayake",
    image: "Prof.Ranjith Dissanayake_UoP.jpg",
    description: "University of Peradeniya",
  },
  {
    category: "Law",
    award: "gold",
    name: "Prof. Kokila Konasinghe",
    image: "Prof. Kokila Konasinghe_UoC.jpg",
    description: "University of Colombo",
  },
  {
    category: "Nursing and Health Sciences",
    award: "gold",
    name: "Prof. Ruwan Jayasinghe",
    image: "Prof.Ruwan Jayasinghe_UoJ.jpg",
    description: "University of Peradeniya",
  },
  {
    category: "Social Sciences",
    award: "gold",
    name: "Prof. Charmalie Nahallage",
    image: "Prof.Charmalie Nahallage_UoJ.jpg",
    description: "University of Sri Jayewardenepura",
  },
  {
    category: "Applied Sciences",
    award: "silver",
    name: "Prof. P. Mangala",
    image: "Prof. P. Mangala_UoRuhuna.jpg",
    description: "University of Ruhuna",
  },
  {
    category: "Business & Management",
    award: "silver",
    name: "Prof. Renuka Herath",
    image: "Prof.Renuka Herath_SLIIT.jpg",
    description: "Sri Lanka Institute of Information Technology",
  },
  {
    category: "Computing",
    award: "silver",
    name: "Prof. Amirthalingam Ramananl",
    image: "Prof.Amirthalingam Ramananl_UoJaf.jpg",
    description: "University of Jaffna",
  },
  {
    category: "Language and Literature",
    award: "silver",
    name: "Dr. Roshan Abeywikramal",
    image: "Dr.Roshan Abeywikramal_UoSab.jpg",
    description: "Sabaragamuwa University of Sri Lanka",
  },
  {
    category: "Nursing and Health Science",
    award: "silver",
    name: "Dr. Bimali Sanjeewani Weerakoon",
    image: "Dr.Bimali Sanjeewani Weerakoon_UoP(absent).jpg",
    description: "University of Peradeniya",
  },
  {
    category: "Applied Sciences",
    award: "bronze",
    name: "Prof. Vajira Bulugahapitiya",
    image: "Prof. vajira Bulugahapitiya_UoRuhuna.jpg",
    description: "University of Ruhuna",
  },
  {
    category: "Computing",
    award: "bronze",
    name: "Dr. Firthouse Hassan Ahamed",
    image: "Dr. Firthouse Hassan Ahamed_South Eastern Uni.jpg",
    description: "South Eastern University of Sri Lanka",
  },
  {
    category: "Language and Literature",
    award: "bronze",
    name: "Prof. Kausumi Vasntha Dhanapala",
    image: "Prof. Kausumi Vasntha Dhanapala_University of Colombo.jpg",
    description: "University of Colombo",
  },
  {
    category: "Mathematics and Statistics",
    award: "bronze",
    name: "Prof. Vasana Chandraseka",
    image: "Prof.Vasana Chandraseka_UoKelaniyaj.jpg",
    description: "University of Kelaniya",
  },
  {
    category: "Social Sciences",
    award: "bronze",
    name: "Prof. Manjula Ranagalage",
    image: "Prof.Manjula Ranagalage_UoRaj.jpg",
    description: "Rajarata University of Sri Lanka",
  },
];
