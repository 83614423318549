import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./styles.css";

import { Parallax, Pagination, Navigation, Autoplay } from "swiper/modules";

export const ImageSlider = ({ visibility = true }) => {
  const awsurl = "https://eventimages2.s3.ap-south-1.amazonaws.com/";
  const images = [
    "Awards.jpg",
    "Chief Guest.jpg",
    "Crowd.jpg",
    "Dancers 1.jpg",
    "Dancers 2.jpg",
    "Dancers 3.jpg",
    "Dr. Malitha.jpg",
    "HE Alkhatani.jpg",
    "Prof. Attalage.jpg",
    "Prof. Cochran.jpg",
  ];
  return (
    <div
      className="flex flex-col h-full min-h-screen judges-bg-color py-10 lg:py-20 relative"
      id="judges"
    >
      <div className="text-center relative z-20">
        <h1
          className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-10 lg:my-0 px-4 lg:px-0 section-title relative mx-auto w-fit"
          style={{ fontFamily: "Bebas Neue" }}
          data-aos="fade-left"
          data-aos-delay="100"
        >
          HIGHLIGHTS OF NATIONAL EDUCATOR AWARDS 2023
        </h1>
      </div>
      <div className="py-4 sm:py-28">
        <Swiper
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
            position: "absolute",
          }}
          speed={600}
          parallax={true}
          pagination={{
            clickable: true,
            type: "bullets",
            bulletClass: "swiper-pagination-bullet images-bullet",
          }}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Parallax, Pagination, Navigation, Autoplay]}
          className="judge-swiper images-swiper h-[70vh] w-full justify-center "
        >
          {/* <div
            slot="container-start"
            className="parallax-bg-judge"
            data-swiper-parallax="-23%"
          ></div> */}
          {images.map((image, index) => (
            // <div
            //   className="w-fit object-contain font-semibold bg-slate-100 rounded-md p-2.5 lg:p-4 flex flex-row justify-center items-center hover:cursor-pointer hover:text-white hover:bg-orange-500  transition-all hover:-translate-y-1"
            //   data-aos="fade-down"
            //   data-aos-delay={100 + 50 * index}
            //   key={image}
            // >
            <SwiperSlide key={index}>
              <img src={`${awsurl}${image}`} alt={image} loading="lazy" />
            </SwiperSlide>
            // </div>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
