import {
  faSearch,
  faGavel,
  faGlobe,
  faCheckCircle,
  faUsers,
  faFileAlt,
  faCogs,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const Aboutus = () => {
  const standfor = [
    {
      name: "Transparency and Rigor",
      description:
        "NEA is committed to maintaining a transparent and rigorous evaluation process. We have developed stringent selection criteria to ensure that only the most deserving educators receive our prestigious awards.",
      icon: faSearch,
    },
    {
      name: "Panel of Esteemed Judges",
      description:
        "Our Evaluation process is overseen by an esteemed panel of judges that are carefully selected from reputable higher education institutions in Sri Lanka, ensuring impartiality and credibility in the evaluation process. Their expertise and credibility guarantee fairness and transparency throughout the selection process.",
      icon: faGavel,
    },
    {
      name: "Celebrating Diversity",
      description:
        "NEA recognizes educators from both state and non-state higher education institutions, encompassing a wide range of academic disciplines. We celebrate the diversity of knowledge and expertise that these educators bring to the table.",
      icon: faGlobe,
    },
  ];

  const operate = [
    {
      name: "Selection Criteria",
      description:
        "We employ a comprehensive set of selection criteria that assess educators' teaching qualifications, research contributions, impact on students and society, contributions to the field of education, national and international collaborations, and other awards and recognitions received.",
      icon: faCheckCircle,
    },
    {
      name: "Panel of Judges",
      description:
        "Our esteemed panel of judges is carefully selected from reputable higher education institutions in Sri Lanka, ensuring impartiality and credibility in the evaluation process.",
      icon: faUsers,
    },
    {
      name: "Applications",
      description:
        "Educators are invited to apply for the National Educator Awards through our user-friendly online portal. This portal serves as the central platform for collecting information about deserving educators.",
      icon: faFileAlt,
    },
    {
      name: "Evaluation Process",
      description:
        "All applications submitted through the online portal undergo a meticulous evaluation by our panel of judges. Shortlisted candidates may also be invited for interviews to further assess their qualifications and contributions.",
      icon: faCogs,
    },
    {
      name: "Awards Ceremony",
      description:
        "The culmination of our efforts is an extravagant awards ceremony held in December 2024, where the selected educators are formally recognized and celebrated for their outstanding contributions to education.",
      icon: faTrophy,
    },
  ];
  return (
    <div
      className="flex flex-col h-full min-h-screen bg-slate-900 category-bg-color "
      id="aboutus"
    >
      <div className="max-w-[1280px] mx-auto py-6 lg:py-16">
        <div className="text-left lg:mx-8 flex justify-start flex-col my-6">
          <h1
            className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-2 lg:my-0 px-8 lg:px-0 section-title relative w-fit "
            style={{ fontFamily: "Bebas Neue" }}
            data-aos="fade-left"
            data-aos-delay="100"
          >
            About Us
          </h1>
          <p
            className="mt-4 lg:mt-8 text-white text-md lg:text-lg w-full lg:w-1/2 px-8"
            data-aos="fade-right"
            data-aos-delay="100"
          >
            Welcome to the National Educator Awards (NEA), where excellence in
            education is celebrated and recognized. NEA is a prestigious
            initiative dedicated to honoring outstanding educators across Sri
            Lanka who have made remarkable contributions to their respective
            academic disciplines. Our mission is to shine a spotlight on the
            incredible individuals who shape the future of education in our
            nation.
          </p>
        </div>
        <div className="text-right lg:mx-8 flex items-end flex-col my-6">
          <h1
            className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-2 lg:my-0 px-8 lg:px-0 section-title relative w-fit "
            style={{ fontFamily: "Bebas Neue" }}
            data-aos="fade-right"
            data-aos-delay="100"
          >
            Our Vision
          </h1>
          <p
            className="mt-4 lg:mt-8 text-white text-md lg:text-lg w-full lg:w-1/2 px-8"
            data-aos="fade-left"
            data-aos-delay="100"
          >
            At NEA, our vision is clear: we aim to inspire, acknowledge, and
            reward exceptional educators whose dedication and passion have
            positively impacted students and society as a whole. We believe that
            by recognizing these unsung heroes, we can foster a culture of
            excellence, innovation, and ethical teaching practices within the
            field of education.
          </p>
        </div>
      </div>
      <div className="py-6 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h1
              className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-10 lg:my-0 px-4 lg:px-0 relative mx-auto w-fit"
              style={{ fontFamily: "Bebas Neue" }}
              data-aos="fade-left"
              data-aos-delay="100"
            >
              What We Stand For
            </h1>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              {standfor.map((feature, index) => (
                <div
                  key={index}
                  className="relative pl-16"
                  data-aos="fade-up"
                  data-aos-delay={100 + 50 * index}
                >
                  <dt className="text-xl font-semibold leading-7 text-orange-500">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-white/10">
                      <FontAwesomeIcon
                        icon={feature.icon}
                        size="xl"
                        className="icon"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-lg leading-7 text-gray-300">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="py-6 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h1
              className="text-4xl font-bold sm:text-6xl capitalize tracking-wider text-[#dd7538] my-10 lg:my-0 px-4 lg:px-0 relative mx-auto w-fit"
              style={{ fontFamily: "Bebas Neue" }}
              data-aos="fade-left"
              data-aos-delay="100"
            >
              How We Operate
            </h1>
          </div>
          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-3 lg:gap-y-16">
              {operate.map((feature, index) => (
                <div
                  key={index}
                  className="relative pl-16"
                  data-aos="fade-up"
                  data-aos-delay={100 + 50 * index}
                >
                  <dt className="text-xl font-semibold leading-7 text-orange-500">
                    <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-white/10">
                      <FontAwesomeIcon
                        icon={feature.icon}
                        size="xl"
                        className="icon"
                      />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-2 text-lg leading-7 text-gray-300">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};
